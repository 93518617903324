import { type FC } from "react";
import { Modal, type ModalProps, Button } from "react-bootstrap";
import { ReactComponent as IconDestructbara } from "images/icons/destructbara.svg";

interface Props extends ModalProps {
  onRetry: () => void;
  onCancel: () => void;
}

const ErrorModal: FC<Props> = ({ onRetry, onCancel, ...modalProps }) => {
  return (
    <Modal {...modalProps} className="mt-5">
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="d-flex justify-content-center">
          <IconDestructbara style={{ width: "140px" }} />
        </div>
        <div className="text-center mb-5">
          <p className="h3">Neočekávaná chyba</p>
          <p>
            Vypadá to, že nám kapybara překousala kabel a došlo k neočekávané chybě, kvůli které jsme nemohli opravit
            odpověď na otázku.
          </p>
          <p className="fw-bold">Zkuste na úlohu prosím odpovědět znovu.</p>
        </div>
        <Button className="w-100 mb-3" onClick={onRetry}>
          Zkusit odpovědět znovu
        </Button>
        <Button className="w-100" variant="outline-secondary" onClick={onCancel}>
          Přeskočit otázku
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ErrorModal;
