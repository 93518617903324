import { useState, type FC } from "react";
import { Breadcrumb, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";

import { EnvelopeStackIcon, PracticeConfirmationModal } from "components";
import IconArrowBack from "components/Icons/IconArrowBack/IconArrowBack";

import { useAppDispatch } from "store/hooks";
import { removeResults } from "store/slices/topicPractice";
import { usePracticeConfirmationModalLocalStorage } from "components/Modal/PracticeConfirmationModal/usePracticeConfirmationModalLocalStorage";

interface Props {
  percentage: number;
}

interface Props {
  assignmentsCount: number;
  percentage: number;
}

const OrangeEnvelopeHeading: FC<Props> = ({ assignmentsCount, percentage }) => {
  const { t } = useTranslation(["common", "assignment"]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isModalVisible, setModalVisible] = useState(false);
  const { isConfirmationModalAllowed, setSeenModalToLocalStorage } = usePracticeConfirmationModalLocalStorage();

  const handlePracticeStart = () => {
    dispatch(removeResults());
    navigate("/procvicovani/obalka/oranzova");
  };

  const handleSetModalVisible = () => {
    setSeenModalToLocalStorage();
    setModalVisible(true);
  };

  return (
    <>
      <Container className={"container-mw-md mb-20px mb-sm-0"}>
        <div className="bookmarked">
          <div className="bookmarked__header">
            <div className="bookmarked__title">
              <Breadcrumb>
                <li className={"breadcrumb-item"}>
                  <Link to={"/"}>
                    <IconArrowBack />
                    {t("back")}
                  </Link>
                </li>
              </Breadcrumb>

              <h1>
                {t("pageTitle.orangeEnvelopeCount", {
                  ns: "envelope",
                  count: assignmentsCount,
                })}
              </h1>
            </div>

            <EnvelopeStackIcon type="orange" percentage={Math.min(percentage / 100, 1)} />
          </div>

          <p>
            Tyhle už skoro umíš! Občas se ti povedou, ale někdy o kousek uteče plný počet počet bodů. Zamakáme, aby se
            ti povedly vždycky (pak je najdeš v zelené obálce).
          </p>
        </div>

        <Button
          className={"text-uppercase"}
          onClick={() => {
            isConfirmationModalAllowed ? handleSetModalVisible() : handlePracticeStart();
          }}
        >
          {t("trainThisTypeOfAssignments", { ns: "assignments" })}
        </Button>
      </Container>
      <PracticeConfirmationModal
        onConfirm={handlePracticeStart}
        show={isModalVisible}
        onHide={() => {
          setModalVisible(false);
        }}
      />
    </>
  );
};

export default OrangeEnvelopeHeading;
