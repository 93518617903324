import { api } from "api/api";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  Time: { input: any; output: any };
  Void: { input: any; output: any };
}

export interface AchievementsType {
  achievements: Array<UserAchievementType>;
  achievementsNew: Array<UserAchievementType>;
  aggregate: Array<AggregateType>;
}

export interface AchievementsTypeAchievementsArgs {
  count?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface AdmissionTestType {
  date?: Maybe<Scalars["Date"]["output"]>;
  id: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
}

export interface AggregateType {
  achievementType: Scalars["String"]["output"];
  countNew?: Maybe<Scalars["Int"]["output"]>;
  countTotal: Scalars["Int"]["output"];
  dateLast?: Maybe<Scalars["Date"]["output"]>;
}

export interface AggregationsType {
  moodOverall?: Maybe<Scalars["Float"]["output"]>;
  user: UserType;
}

export interface AnswerInput {
  autoEval?: InputMaybe<Array<QuestionAutoInput>>;
  selfEval?: InputMaybe<Scalars["Int"]["input"]>;
  timeSolveReal: Scalars["Int"]["input"];
  userAssignmentId: Scalars["String"]["input"];
}

export interface AnswerType {
  attemptNo: Scalars["Int"]["output"];
  scoreReal: Scalars["Float"]["output"];
  sessionId: Scalars["String"]["output"];
}

export interface AssignmentCloseInput {
  abilityScore: Scalars["Float"]["input"];
  timeLearnReal: Scalars["Int"]["input"];
  tip: Scalars["Boolean"]["input"];
  userAssignmentId: Scalars["String"]["input"];
}

export interface AssignmentType {
  Assignment?: Maybe<AssignmentTypeNested>;
  AssignmentIsInitialized: Scalars["Boolean"]["output"];
  General?: Maybe<GeneralType>;
  GeneralIsInitialized: Scalars["Boolean"]["output"];
  analysis?: Maybe<SharedAnalysisType>;
  assignment?: Maybe<AssignmentTypeNested>;
  assignmentBak?: Maybe<AssignmentTypeNested>;
  general?: Maybe<GeneralType>;
  id: Scalars["String"]["output"];
  isActive: Scalars["Boolean"]["output"];
  isOnboarding: Scalars["Boolean"]["output"];
  scoring?: Maybe<ScoringType>;
  selfEvaluation?: Maybe<SharedSelfEvaluationType>;
}

export interface AssignmentTypeNested {
  Questions?: Maybe<Array<QuestionType>>;
  QuestionsIsInitialized: Scalars["Boolean"]["output"];
  assignmentImage?: Maybe<Scalars["String"]["output"]>;
  assignmentText?: Maybe<Scalars["String"]["output"]>;
  assignmentType: AssignmentTypeType;
  isAnswerCaseSensitive?: Maybe<Scalars["Boolean"]["output"]>;
  printingAttachment?: Maybe<Scalars["String"]["output"]>;
  questions: Array<QuestionType>;
  questionsText?: Maybe<Scalars["String"]["output"]>;
}

export interface AssignmentTypeType {
  name?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
}

export interface BookmarkInput {
  userAssignmentId: Scalars["String"]["input"];
}

export interface BookmarkType {
  id: Scalars["String"]["output"];
  user: UserType;
  userAssignment: UserAssignmentType;
  userId: Scalars["String"]["output"];
}

export interface CombinedTopicType {
  assignments: Array<AssignmentType>;
  id: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  topics: Array<TopicType>;
}

export interface CommonSelfEvaluationType {
  general?: Maybe<SelfEvalGeneralType>;
  options: Array<SelfEvalOptionType>;
}

export interface DatetimeType {
  iso: Scalars["DateTime"]["output"];
}

export interface DeletionType {
  collectionName: Scalars["String"]["output"];
  objectsDeleted: Array<Scalars["String"]["output"]>;
}

export interface DifficultyType {
  id: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
}

export interface EnvelopesSummaryType {
  green: Scalars["Int"]["output"];
  red: Scalars["Int"]["output"];
  yellow: Scalars["Int"]["output"];
}

export interface EvaluationClaimInput {
  questions: Array<Scalars["Boolean"]["input"]>;
  userAssignmentId: Scalars["String"]["input"];
}

export interface FaqType {
  description?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
}

export interface GeneralType {
  AdmissionTest?: Maybe<AdmissionTestType>;
  AdmissionTestIsInitialized: Scalars["Boolean"]["output"];
  admissionTest: AdmissionTestType;
  author: TestAuthorType;
  exerciseSource?: Maybe<Scalars["String"]["output"]>;
  mhdFriendly?: Maybe<Scalars["Boolean"]["output"]>;
  testOrder?: Maybe<Scalars["Int"]["output"]>;
}

export interface LoginInput {
  handshake: Scalars["String"]["input"];
}

export interface LoginType {
  accessToken?: Maybe<Scalars["String"]["output"]>;
  status: Scalars["String"]["output"];
}

export interface Mutation {
  answer: AnswerType;
  claimEvaluation: AnswerType;
  claimScoreMax: AnswerType;
  deleteBookmark: DeletionType;
  deleteTimetableDay: DeletionType;
  deleteTimetableWeek: DeletionType;
  login: LoginType;
  parentWatchUpdate: ParentWatchType;
  sessionClose: SessionCloseType;
  sessionCreate: SessionType;
  sessionStart: SessionType;
  setBookmark: BookmarkType;
  setTimetable: TimetableType;
  setTimetableGeneric: TimetableGenericType;
  solutionUploadConfirm?: Maybe<Scalars["Void"]["output"]>;
  solutionUploadUrl: SignedUrlType;
  timeTravel: DatetimeType;
  timeTravelReset: DatetimeType;
  updateOnboarding: OnboardingType;
  updatePhone: PhoneValidationType;
  updateUser: UserType;
  userTopicUpdate: UserTopicType;
  validatePhone: PhoneValidationType;
}

export interface MutationAnswerArgs {
  input: AnswerInput;
}

export interface MutationClaimEvaluationArgs {
  claimInput: EvaluationClaimInput;
}

export interface MutationClaimScoreMaxArgs {
  input: ScoreClaimInput;
}

export interface MutationDeleteBookmarkArgs {
  id: Scalars["String"]["input"];
}

export interface MutationDeleteTimetableDayArgs {
  dayDate: Array<Scalars["Date"]["input"]>;
}

export interface MutationDeleteTimetableWeekArgs {
  weekStr: Array<Scalars["String"]["input"]>;
}

export interface MutationLoginArgs {
  loginInput: LoginInput;
}

export interface MutationParentWatchUpdateArgs {
  inputRewards: ParentWatchInput;
}

export interface MutationSessionCloseArgs {
  input: SessionCloseInput;
}

export interface MutationSessionCreateArgs {
  lengthPreference: Scalars["Int"]["input"];
}

export interface MutationSetBookmarkArgs {
  input: BookmarkInput;
}

export interface MutationSetTimetableArgs {
  input: TimetableInput;
}

export interface MutationSetTimetableGenericArgs {
  input: TimetableGenericInput;
}

export interface MutationSolutionUploadConfirmArgs {
  input: SolutionUploadConfirmationInput;
}

export interface MutationSolutionUploadUrlArgs {
  input: SolutionUploadInput;
}

export interface MutationTimeTravelArgs {
  input: TimeMachineInput;
}

export interface MutationUpdateOnboardingArgs {
  input: OnboardingInput;
}

export interface MutationUpdatePhoneArgs {
  input: PhoneInput;
}

export interface MutationUpdateUserArgs {
  input: UserInput;
}

export interface MutationUserTopicUpdateArgs {
  id: Scalars["String"]["input"];
  input: UserTopicInput;
}

export interface MutationValidatePhoneArgs {
  input: PhoneValidationInput;
}

export interface OnboardingInput {
  finished?: InputMaybe<Scalars["Boolean"]["input"]>;
  introduced?: InputMaybe<Scalars["Boolean"]["input"]>;
  welcome?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface OnboardingType {
  finished: Scalars["Boolean"]["output"];
  introduced: Scalars["Boolean"]["output"];
  welcome: Scalars["Boolean"]["output"];
}

export interface OptionType {
  additionalText?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
}

export interface OutageType {
  endTime?: Maybe<Scalars["Date"]["output"]>;
  startTime?: Maybe<Scalars["Date"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
}

export interface ParentRewardInput {
  month?: InputMaybe<Scalars["Int"]["input"]>;
  targetScore?: InputMaybe<Scalars["Int"]["input"]>;
  text: Scalars["String"]["input"];
  type: Scalars["String"]["input"];
}

export interface ParentRewardType {
  month?: Maybe<Scalars["Int"]["output"]>;
  targetScore?: Maybe<Scalars["Int"]["output"]>;
  text: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
}

export interface ParentWatchInput {
  message?: InputMaybe<Scalars["String"]["input"]>;
  rewards: Array<ParentRewardInput>;
}

export interface ParentWatchType {
  message?: Maybe<Scalars["String"]["output"]>;
  rewards: Array<ParentRewardType>;
  user: UserType;
}

export interface PhoneInput {
  phoneNumber: Scalars["String"]["input"];
  smsEnabled: Scalars["Boolean"]["input"];
}

export interface PhoneValidationInput {
  validationCode: Scalars["String"]["input"];
}

export interface PhoneValidationType {
  datetimeLast?: Maybe<Scalars["String"]["output"]>;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  status: Scalars["String"]["output"];
  waiting: Scalars["Boolean"]["output"];
}

export interface Query {
  aggregations: AggregationsType;
  assignments: Array<AssignmentType>;
  blindSpotTopics: Array<UserTopicType>;
  bookmarks: Array<BookmarkType>;
  bookmarksCached: Array<BookmarkType>;
  checkAchievements: AchievementsType;
  combinedTopics: Array<CombinedTopicType>;
  combinedTopicsCached: Array<CombinedTopicType>;
  currentDatetime: DatetimeType;
  currentUser: UserType;
  displayOutages: Array<OutageType>;
  envelopesSummary: EnvelopesSummaryType;
  faqAll: Array<FaqType>;
  lastAchievements: Array<UserAchievementType>;
  onboarding: OnboardingType;
  onboardingAssignments: Array<AssignmentType>;
  parentWatch: ParentWatchType;
  schools: Array<SchoolType>;
  subjects: Array<SubjectType>;
  subjectsCached: Array<SubjectType>;
  superTopics: Array<SuperTopicType>;
  superTopicsCached: Array<SuperTopicType>;
  systemInfo: SystemInfoType;
  testPresence: Array<TestPresenceType>;
  timetable: TimeWindowType;
  timetableGenericAll: Array<TimetableGenericType>;
  timetableGenericCurrent: TimetableGenericType;
  timetableGenericDate: TimetableGenericType;
  topics: Array<TopicType>;
  training: TrainingType;
  trainingEnvelope: Array<UserAssignmentType>;
  trainingMhd: TrainingType;
  userAssignments: UserAssignmentTypePaginationWindowType;
  userAssignmentsCached: UserAssignmentTypePaginationWindowType;
  userAssignmentsFulltext: UserAssignmentTypePaginationWindowType;
  userCombinedTopic: UserCombinedTopicType;
  userCombinedTopics: Array<UserCombinedTopicType>;
  userCombinedTopicsCached: Array<UserCombinedTopicType>;
  userSessionReview: Array<SessionReviewType>;
  userSubject: UserSubjectType;
  userSubjects: Array<UserSubjectType>;
  userSubjectsCached: Array<UserSubjectType>;
  userSuperTopic: UserSuperTopicType;
  userSuperTopics: Array<UserSuperTopicType>;
  userSuperTopicsCached: Array<UserSuperTopicType>;
  userTopic: UserTopicType;
  userTopics: Array<UserTopicType>;
  userTopicsWorst: Array<UserTopicType>;
}

export interface QueryAssignmentsArgs {
  idList?: InputMaybe<Array<Scalars["String"]["input"]>>;
}

export interface QueryCheckAchievementsArgs {
  check?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface QueryCombinedTopicsArgs {
  idList?: InputMaybe<Array<Scalars["String"]["input"]>>;
}

export interface QueryCombinedTopicsCachedArgs {
  idList?: InputMaybe<Array<Scalars["String"]["input"]>>;
}

export interface QueryLastAchievementsArgs {
  count: Scalars["Int"]["input"];
}

export interface QuerySubjectsArgs {
  idList?: InputMaybe<Array<Scalars["String"]["input"]>>;
}

export interface QuerySubjectsCachedArgs {
  idList?: InputMaybe<Array<Scalars["String"]["input"]>>;
}

export interface QuerySuperTopicsArgs {
  idList?: InputMaybe<Array<Scalars["String"]["input"]>>;
}

export interface QuerySuperTopicsCachedArgs {
  idList?: InputMaybe<Array<Scalars["String"]["input"]>>;
}

export interface QueryTestPresenceArgs {
  refresh?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface QueryTimetableArgs {
  dateFrom: Scalars["Date"]["input"];
  dateTo: Scalars["Date"]["input"];
}

export interface QueryTimetableGenericDateArgs {
  dayDate: Scalars["Date"]["input"];
}

export interface QueryTopicsArgs {
  idList?: InputMaybe<Array<Scalars["String"]["input"]>>;
}

export interface QueryTrainingArgs {
  lengthPreference: Scalars["Int"]["input"];
}

export interface QueryTrainingEnvelopeArgs {
  envelope: Scalars["String"]["input"];
}

export interface QueryTrainingMhdArgs {
  lengthPreference: Scalars["Int"]["input"];
}

export interface QueryUserAssignmentsArgs {
  filterInput: UserAssignmentFilterInput;
}

export interface QueryUserAssignmentsCachedArgs {
  filterInput: UserAssignmentFilterInput;
}

export interface QueryUserAssignmentsFulltextArgs {
  fulltextInput: UserAssignmentFulltextInput;
}

export interface QueryUserCombinedTopicArgs {
  id: Scalars["String"]["input"];
}

export interface QueryUserSessionReviewArgs {
  dateFrom: Scalars["Date"]["input"];
}

export interface QueryUserSubjectArgs {
  id: Scalars["String"]["input"];
}

export interface QueryUserSuperTopicArgs {
  id: Scalars["String"]["input"];
}

export interface QueryUserTopicArgs {
  id: Scalars["String"]["input"];
}

export interface QuestionAnalysisSharedType {
  explainerVideo?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  writtenVideo?: Maybe<Scalars["String"]["output"]>;
}

export interface QuestionAnalysisType {
  explainerVideo?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  writtenVideo?: Maybe<Scalars["String"]["output"]>;
}

export interface QuestionAutoInput {
  answers: Array<Scalars["String"]["input"]>;
}

export interface QuestionType {
  correctAnswers: Array<Maybe<Scalars["String"]["output"]>>;
  heading?: Maybe<Scalars["String"]["output"]>;
  options: Array<OptionType>;
  placeholder?: Maybe<Scalars["String"]["output"]>;
  selfEvaluation?: Maybe<CommonSelfEvaluationType>;
  showAbilityScore?: Maybe<Scalars["Boolean"]["output"]>;
  solutionAnalysis?: Maybe<QuestionAnalysisType>;
}

export interface SchoolType {
  name?: Maybe<Scalars["String"]["output"]>;
  pointsForAcceptance?: Maybe<Scalars["Int"]["output"]>;
}

export interface ScoreClaimInput {
  userAssignmentId: Scalars["String"]["input"];
}

export interface ScoringMethodType {
  id: Scalars["String"]["output"];
  scoringMethod?: Maybe<Scalars["String"]["output"]>;
}

export interface ScoringType {
  canTeacherEvaluate?: Maybe<Scalars["Boolean"]["output"]>;
  difficulty: DifficultyType;
  scoreMax?: Maybe<Scalars["Int"]["output"]>;
  scoringMethod: ScoringMethodType;
  selfEvaluation: SelfEvaluationType;
  timeToSolve?: Maybe<Scalars["Int"]["output"]>;
}

export interface SelfEvalGeneralType {
  image?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
}

export interface SelfEvalOptionType {
  image?: Maybe<Scalars["String"]["output"]>;
  scoreReal?: Maybe<Scalars["Int"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
}

export interface SelfEvaluationType {
  id: Scalars["String"]["output"];
  selfEvaluation?: Maybe<Scalars["String"]["output"]>;
}

export interface SessionCloseInput {
  assignmentsClose: Array<AssignmentCloseInput>;
  mood: Scalars["Int"]["input"];
  moodText?: InputMaybe<Scalars["String"]["input"]>;
}

export interface SessionCloseType {
  assignmentsBase: Scalars["Int"]["output"];
  assignmentsBonus: Scalars["Int"]["output"];
  assignmentsTotal: Scalars["Int"]["output"];
  finished: Scalars["Boolean"]["output"];
  lengthActual: Scalars["Int"]["output"];
  lengthPreference: Scalars["Int"]["output"];
  scoreRealTotal: Scalars["Int"]["output"];
  sessionId: Scalars["String"]["output"];
}

export interface SessionReviewType {
  dateTested: Scalars["Date"]["output"];
  finished: Scalars["Boolean"]["output"];
  scoreMaxTotal?: Maybe<Scalars["Int"]["output"]>;
  scoreRealTotal: Scalars["Int"]["output"];
  sessionId: Scalars["String"]["output"];
}

export interface SessionType {
  alreadyStarted: Scalars["Boolean"]["output"];
  assignmentsBase: Scalars["Int"]["output"];
  assignmentsBonus: Scalars["Int"]["output"];
  assignmentsTotal: Scalars["Int"]["output"];
  id: Scalars["String"]["output"];
  lengthActual: Scalars["Int"]["output"];
  lengthPreference: Scalars["Int"]["output"];
  userAssignments: Array<UserAssignmentType>;
}

export interface SharedAnalysisType {
  hasSharedAnalysis?: Maybe<Scalars["Boolean"]["output"]>;
  sharedAnalysis?: Maybe<QuestionAnalysisSharedType>;
}

export interface SharedSelfEvaluationType {
  hasSharedSelfEvaluation: Scalars["Boolean"]["output"];
  sharedSelfEvaluation?: Maybe<CommonSelfEvaluationType>;
}

export interface SignedUrlType {
  command: Scalars["String"]["output"];
  uploadId: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
}

export interface SolutionUploadConfirmationInput {
  solutionUploadId: Scalars["String"]["input"];
}

export interface SolutionUploadInput {
  contentType: Scalars["String"]["input"];
  fileExtension: Scalars["String"]["input"];
  fileSize: Scalars["Int"]["input"];
  userAssignmentId: Scalars["String"]["input"];
}

export interface StudyDayGenericInput {
  dayName: Scalars["String"]["input"];
  duration: Scalars["Int"]["input"];
  startAt: Scalars["Time"]["input"];
}

export interface StudyDayGenericType {
  dayName: Scalars["String"]["output"];
  duration?: Maybe<Scalars["Int"]["output"]>;
  startAt?: Maybe<Scalars["Time"]["output"]>;
}

export interface StudyDayInput {
  date?: InputMaybe<Scalars["Date"]["input"]>;
  duration?: InputMaybe<Scalars["Int"]["input"]>;
  startAt?: InputMaybe<Scalars["Time"]["input"]>;
}

export interface StudyDayType {
  date?: Maybe<Scalars["Date"]["output"]>;
  dayIndex?: Maybe<Scalars["Int"]["output"]>;
  dayName?: Maybe<Scalars["String"]["output"]>;
  duration?: Maybe<Scalars["Int"]["output"]>;
  durationReal?: Maybe<Scalars["Int"]["output"]>;
  sessionFinished?: Maybe<Scalars["Boolean"]["output"]>;
  startAt?: Maybe<Scalars["Time"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  weekStr?: Maybe<Scalars["String"]["output"]>;
}

export interface SubjectType {
  SuperTopics?: Maybe<Array<SuperTopicType>>;
  SuperTopicsIsInitialized: Scalars["Boolean"]["output"];
  abbreviation?: Maybe<Scalars["String"]["output"]>;
  appAbbr?: Maybe<Scalars["String"]["output"]>;
  appName?: Maybe<Scalars["String"]["output"]>;
  assignments: Array<AssignmentType>;
  combinedTopics: Array<CombinedTopicType>;
  id: Scalars["String"]["output"];
  isFavorite?: Maybe<Scalars["Boolean"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  superTopics: Array<SuperTopicType>;
  topics: Array<TopicType>;
}

export interface SuperTopicType {
  CombinedTopics?: Maybe<Array<CombinedTopicType>>;
  CombinedTopicsIsInitialized: Scalars["Boolean"]["output"];
  assignments: Array<AssignmentType>;
  combinedTopics: Array<CombinedTopicType>;
  id: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  topics: Array<TopicType>;
}

export interface SystemInfoType {
  containerId?: Maybe<Scalars["String"]["output"]>;
  dfAlh?: Maybe<Scalars["String"]["output"]>;
  dfH?: Maybe<Scalars["String"]["output"]>;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  free?: Maybe<Scalars["String"]["output"]>;
  status: Scalars["String"]["output"];
}

export interface TestAuthorType {
  id: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
}

export interface TestPresenceType {
  absolved?: Maybe<Scalars["Boolean"]["output"]>;
  dateTime: Scalars["String"]["output"];
  form?: Maybe<Scalars["String"]["output"]>;
  scoreCzech?: Maybe<Scalars["Int"]["output"]>;
  scoreMath?: Maybe<Scalars["Int"]["output"]>;
  testId: Scalars["String"]["output"];
}

export interface TimeMachineInput {
  day?: InputMaybe<Scalars["Int"]["input"]>;
  month?: InputMaybe<Scalars["Int"]["input"]>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface TimeWindowType {
  dateFrom: Scalars["Date"]["output"];
  dateTo: Scalars["Date"]["output"];
  daysFulfillsPlan: Scalars["Int"]["output"];
  elixirOfLifeCount: Scalars["Int"]["output"];
  timetable: Array<TimetableType>;
}

export interface TimetableGenericInput {
  plan: Array<StudyDayGenericInput>;
  validFrom: Scalars["Date"]["input"];
  validTo: Scalars["Date"]["input"];
}

export interface TimetableGenericType {
  created: Scalars["DateTime"]["output"];
  plan: Array<StudyDayGenericType>;
  validFrom: Scalars["Date"]["output"];
  validTo: Scalars["Date"]["output"];
}

export interface TimetableInput {
  plan: Array<StudyDayInput>;
}

export interface TimetableType {
  dateEnd?: Maybe<Scalars["Date"]["output"]>;
  dateStart?: Maybe<Scalars["Date"]["output"]>;
  plan: Array<StudyDayType>;
}

export interface TopicType {
  assignments: Array<AssignmentType>;
  id: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
}

export interface TrainingType {
  assignmentsTotal: Scalars["Int"]["output"];
  lengthActual: Scalars["Int"]["output"];
  lengthPreference: Scalars["Int"]["output"];
  userAssignments: Array<UserAssignmentType>;
}

export interface UserAchievementType {
  dateAchieved: Scalars["Date"]["output"];
  goal: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
}

export interface UserAssignmentFilterInput {
  combinedTopicId?: InputMaybe<Scalars["String"]["input"]>;
  envelope?: InputMaybe<Scalars["String"]["input"]>;
  idList?: InputMaybe<Array<Scalars["String"]["input"]>>;
  isOnboarding?: InputMaybe<Scalars["Boolean"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  noPagination?: InputMaybe<Scalars["Boolean"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  superTopicId?: InputMaybe<Scalars["String"]["input"]>;
  topicId?: InputMaybe<Scalars["String"]["input"]>;
}

export interface UserAssignmentFulltextInput {
  fulltext: Scalars["String"]["input"];
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface UserAssignmentType {
  Assignment?: Maybe<AssignmentType>;
  AssignmentIsInitialized: Scalars["Boolean"]["output"];
  AssignmentType?: Maybe<AssignmentType>;
  abilityScore?: Maybe<Scalars["Float"]["output"]>;
  assignment?: Maybe<AssignmentType>;
  attempts?: Maybe<Scalars["Int"]["output"]>;
  combinedTopic?: Maybe<CombinedTopicType>;
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  envelope?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  interval?: Maybe<Scalars["Int"]["output"]>;
  isOnboarding?: Maybe<Scalars["Boolean"]["output"]>;
  scoreMax?: Maybe<Scalars["Int"]["output"]>;
  scoreReal?: Maybe<Scalars["Int"]["output"]>;
  sessionOrder?: Maybe<Scalars["Int"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  subject?: Maybe<SubjectType>;
  superTopic?: Maybe<SuperTopicType>;
  testLatest?: Maybe<Scalars["Date"]["output"]>;
  testNextDate?: Maybe<Scalars["Date"]["output"]>;
  testNextPriority?: Maybe<Scalars["String"]["output"]>;
  timeToLearn?: Maybe<Scalars["Int"]["output"]>;
  timeToSolve?: Maybe<Scalars["Int"]["output"]>;
  topic?: Maybe<TopicType>;
}

export interface UserAssignmentTypePaginationWindowType {
  items: Array<UserAssignmentType>;
  totalCount: Scalars["Int"]["output"];
}

export interface UserCombinedTopicType {
  AssignmentsGreen?: Maybe<Scalars["Int"]["output"]>;
  AssignmentsGreenIsInitialized: Scalars["Boolean"]["output"];
  AssignmentsRed?: Maybe<Scalars["Int"]["output"]>;
  AssignmentsRedIsInitialized: Scalars["Boolean"]["output"];
  AssignmentsYellow?: Maybe<Scalars["Int"]["output"]>;
  AssignmentsYellowIsInitialized: Scalars["Boolean"]["output"];
  CombinedTopic?: Maybe<CombinedTopicType>;
  CombinedTopicIsInitialized: Scalars["Boolean"]["output"];
  CombinedTopicType?: Maybe<CombinedTopicType>;
  HasAssignments?: Maybe<Scalars["Boolean"]["output"]>;
  HasAssignmentsIsInitialized: Scalars["Boolean"]["output"];
  ScoreAggregatePercent?: Maybe<Scalars["Int"]["output"]>;
  ScoreAggregatePercentIsInitialized: Scalars["Boolean"]["output"];
  assignments: Scalars["Int"]["output"];
  assignmentsGreen: Scalars["Int"]["output"];
  assignmentsRed: Scalars["Int"]["output"];
  assignmentsYellow: Scalars["Int"]["output"];
  combinedTopic?: Maybe<CombinedTopicType>;
  hasAssignments: Scalars["Boolean"]["output"];
  hasAssignmentsGreen: Scalars["Boolean"]["output"];
  hasAssignmentsRed: Scalars["Boolean"]["output"];
  hasAssignmentsYellow: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  scoreAggregatePercent?: Maybe<Scalars["Int"]["output"]>;
}

export interface UserInput {
  bookmarkedExercisesCount?: InputMaybe<Scalars["Int"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  frequency?: InputMaybe<Scalars["String"]["input"]>;
  parentEmail?: InputMaybe<Scalars["String"]["input"]>;
  preferredSubject?: InputMaybe<Scalars["String"]["input"]>;
  withSound?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface UserSubjectType {
  ScoreAggregatePercent?: Maybe<Scalars["Int"]["output"]>;
  ScoreAggregatePercentIsInitialized: Scalars["Boolean"]["output"];
  assignments: Scalars["Int"]["output"];
  assignmentsGreen: Scalars["Int"]["output"];
  assignmentsRed: Scalars["Int"]["output"];
  assignmentsYellow: Scalars["Int"]["output"];
  hasAssignments: Scalars["Boolean"]["output"];
  hasAssignmentsGreen: Scalars["Boolean"]["output"];
  hasAssignmentsRed: Scalars["Boolean"]["output"];
  hasAssignmentsYellow: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  scoreAggregatePercent?: Maybe<Scalars["Int"]["output"]>;
  subject?: Maybe<SubjectType>;
}

export interface UserSuperTopicType {
  HasAssignments?: Maybe<Scalars["Boolean"]["output"]>;
  HasAssignmentsIsInitialized: Scalars["Boolean"]["output"];
  ScoreAggregatePercent?: Maybe<Scalars["Int"]["output"]>;
  ScoreAggregatePercentIsInitialized: Scalars["Boolean"]["output"];
  assignments: Scalars["Int"]["output"];
  assignmentsGreen: Scalars["Int"]["output"];
  assignmentsRed: Scalars["Int"]["output"];
  assignmentsYellow: Scalars["Int"]["output"];
  hasAssignments: Scalars["Boolean"]["output"];
  hasAssignmentsGreen: Scalars["Boolean"]["output"];
  hasAssignmentsRed: Scalars["Boolean"]["output"];
  hasAssignmentsYellow: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  scoreAggregatePercent?: Maybe<Scalars["Int"]["output"]>;
  superTopic?: Maybe<SuperTopicType>;
}

export interface UserTopicInput {
  isBlindSpot: Scalars["Boolean"]["input"];
}

export interface UserTopicType {
  abilityScoreAll?: Maybe<Scalars["Float"]["output"]>;
  abilityScoreLast3?: Maybe<Scalars["Float"]["output"]>;
  assignments: Scalars["Int"]["output"];
  assignmentsGreen: Scalars["Int"]["output"];
  assignmentsRed: Scalars["Int"]["output"];
  assignmentsYellow: Scalars["Int"]["output"];
  combinedTopic?: Maybe<CombinedTopicType>;
  hasAssignments: Scalars["Boolean"]["output"];
  hasAssignmentsGreen: Scalars["Boolean"]["output"];
  hasAssignmentsRed: Scalars["Boolean"]["output"];
  hasAssignmentsYellow: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  isBlindSpot: Scalars["Boolean"]["output"];
  isLast3Wrong: Scalars["Boolean"]["output"];
  scoreAggregatePercent?: Maybe<Scalars["Int"]["output"]>;
  topic?: Maybe<TopicType>;
}

export interface UserType {
  admissionsDeadline?: Maybe<Scalars["Date"]["output"]>;
  daysFulfillsPlan?: Maybe<Scalars["Int"]["output"]>;
  elixirOfLifeCount?: Maybe<Scalars["Int"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstLogin?: Maybe<Scalars["Date"]["output"]>;
  frequency?: Maybe<Scalars["String"]["output"]>;
  grade?: Maybe<Scalars["String"]["output"]>;
  idExternalZona?: Maybe<Scalars["String"]["output"]>;
  lastLogin?: Maybe<Scalars["Date"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  onboarding: OnboardingType;
  parentEmail?: Maybe<Scalars["String"]["output"]>;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  phoneValidationApiErrorMessage?: Maybe<Scalars["String"]["output"]>;
  phoneValidationCode?: Maybe<Scalars["String"]["output"]>;
  phoneValidationDatetimeLast?: Maybe<Scalars["String"]["output"]>;
  phoneValidationPhoneNumber?: Maybe<Scalars["String"]["output"]>;
  phoneValidationStatus?: Maybe<Scalars["String"]["output"]>;
  preferredSchools: Array<SchoolType>;
  preferredSubject?: Maybe<Scalars["String"]["output"]>;
  sessionsAchieved?: Maybe<Scalars["Int"]["output"]>;
  sex?: Maybe<Scalars["String"]["output"]>;
  smsEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  testsAbsolved?: Maybe<Scalars["Int"]["output"]>;
  testsToBuy?: Maybe<Scalars["Int"]["output"]>;
  testsToUnlock?: Maybe<Scalars["Int"]["output"]>;
  vocative?: Maybe<Scalars["String"]["output"]>;
  withSound?: Maybe<Scalars["Boolean"]["output"]>;
}

export type AssignmentCombinedTopicHierarchyFragment = { combinedTopic?: { id: string } | null };

export type AssignmentDetailFragment = {
  id: string;
  isActive: boolean;
  assignment?: {
    questionsText?: string | null;
    assignmentText?: string | null;
    assignmentImage?: string | null;
    printingAttachment?: string | null;
    isAnswerCaseSensitive?: boolean | null;
    assignmentType: { type?: string | null };
    questions: Array<{
      heading?: string | null;
      placeholder?: string | null;
      showAbilityScore?: boolean | null;
      options: Array<{ value?: string | null; additionalText?: string | null }>;
      solutionAnalysis?: {
        text?: string | null;
        image?: string | null;
        writtenVideo?: string | null;
        explainerVideo?: string | null;
      } | null;
      selfEvaluation?: {
        general?: { text?: string | null; image?: string | null } | null;
        options: Array<{ text?: string | null; image?: string | null; scoreReal?: number | null }>;
      } | null;
    }>;
  } | null;
  general?: { testOrder?: number | null; admissionTest: { name?: string | null } } | null;
  scoring?: {
    canTeacherEvaluate?: boolean | null;
    scoringMethod: { scoringMethod?: string | null };
    selfEvaluation: { selfEvaluation?: string | null };
  } | null;
  analysis?: {
    hasSharedAnalysis?: boolean | null;
    sharedAnalysis?: {
      text?: string | null;
      image?: string | null;
      writtenVideo?: string | null;
      explainerVideo?: string | null;
    } | null;
  } | null;
  selfEvaluation?: {
    hasSharedSelfEvaluation: boolean;
    sharedSelfEvaluation?: {
      general?: { text?: string | null; image?: string | null } | null;
      options: Array<{ text?: string | null; image?: string | null; scoreReal?: number | null }>;
    } | null;
  } | null;
};

export type AssignmentDetailForBookmarkFragment = {
  id: string;
  isActive: boolean;
  assignment?: {
    assignmentText?: string | null;
    questionsText?: string | null;
    questions: Array<{ heading?: string | null }>;
  } | null;
  general?: { testOrder?: number | null; admissionTest: { name?: string | null } } | null;
};

export type AssignmentExerciseFragment = {
  id: string;
  isActive: boolean;
  assignment?: {
    questionsText?: string | null;
    assignmentText?: string | null;
    assignmentImage?: string | null;
    printingAttachment?: string | null;
    isAnswerCaseSensitive?: boolean | null;
    questions: Array<{
      heading?: string | null;
      placeholder?: string | null;
      showAbilityScore?: boolean | null;
      options: Array<{ value?: string | null; additionalText?: string | null }>;
      selfEvaluation?: {
        general?: { text?: string | null; image?: string | null } | null;
        options: Array<{ text?: string | null; image?: string | null; scoreReal?: number | null }>;
      } | null;
      solutionAnalysis?: {
        text?: string | null;
        image?: string | null;
        writtenVideo?: string | null;
        explainerVideo?: string | null;
      } | null;
    }>;
    assignmentType: { type?: string | null };
  } | null;
  scoring?: {
    canTeacherEvaluate?: boolean | null;
    scoreMax?: number | null;
    timeToSolve?: number | null;
    scoringMethod: { scoringMethod?: string | null };
    selfEvaluation: { selfEvaluation?: string | null };
  } | null;
  analysis?: {
    hasSharedAnalysis?: boolean | null;
    sharedAnalysis?: {
      text?: string | null;
      image?: string | null;
      writtenVideo?: string | null;
      explainerVideo?: string | null;
    } | null;
  } | null;
  selfEvaluation?: {
    hasSharedSelfEvaluation: boolean;
    sharedSelfEvaluation?: {
      general?: { text?: string | null; image?: string | null } | null;
      options: Array<{ text?: string | null; image?: string | null; scoreReal?: number | null }>;
    } | null;
  } | null;
};

export type AssignmentForBookmarkFragment = {
  userAssignment: {
    id: string;
    envelope?: string | null;
    assignment?: {
      id: string;
      isActive: boolean;
      assignment?: {
        assignmentText?: string | null;
        questionsText?: string | null;
        questions: Array<{ heading?: string | null }>;
      } | null;
      general?: { testOrder?: number | null; admissionTest: { name?: string | null } } | null;
    } | null;
    subject?: { appName?: string | null } | null;
  };
};

export type AssignmentForTopicFragment = {
  id: string;
  isActive: boolean;
  assignment?: {
    assignmentText?: string | null;
    questionsText?: string | null;
    questions: Array<{ heading?: string | null }>;
  } | null;
  general?: { testOrder?: number | null; admissionTest: { name?: string | null } } | null;
};

export type AssignmentPracticeFragment = {
  id: string;
  isActive: boolean;
  assignment?: {
    questionsText?: string | null;
    assignmentText?: string | null;
    assignmentImage?: string | null;
    printingAttachment?: string | null;
    isAnswerCaseSensitive?: boolean | null;
    assignmentType: { type?: string | null };
    questions: Array<{
      heading?: string | null;
      placeholder?: string | null;
      showAbilityScore?: boolean | null;
      options: Array<{ value?: string | null; additionalText?: string | null }>;
      solutionAnalysis?: {
        text?: string | null;
        image?: string | null;
        writtenVideo?: string | null;
        explainerVideo?: string | null;
      } | null;
      selfEvaluation?: {
        general?: { text?: string | null; image?: string | null } | null;
        options: Array<{ text?: string | null; image?: string | null; scoreReal?: number | null }>;
      } | null;
    }>;
  } | null;
  general?: { testOrder?: number | null; admissionTest: { name?: string | null } } | null;
  scoring?: {
    scoreMax?: number | null;
    scoringMethod: { scoringMethod?: string | null };
    selfEvaluation: { selfEvaluation?: string | null };
  } | null;
  analysis?: {
    hasSharedAnalysis?: boolean | null;
    sharedAnalysis?: {
      text?: string | null;
      image?: string | null;
      writtenVideo?: string | null;
      explainerVideo?: string | null;
    } | null;
  } | null;
  selfEvaluation?: {
    hasSharedSelfEvaluation: boolean;
    sharedSelfEvaluation?: {
      general?: { text?: string | null; image?: string | null } | null;
      options: Array<{ text?: string | null; image?: string | null; scoreReal?: number | null }>;
    } | null;
  } | null;
};

export type AssignmentPracticeMinimumFragment = {
  isActive: boolean;
  assignment?: { printingAttachment?: string | null } | null;
};

export type AssignmentPracticeMinimumMhdFragment = { isActive: boolean };

export type AssignmentSuperTopicHierarchyFragment = {
  superTopic?: { id: string } | null;
  subject?: { id: string } | null;
};

export type BadgeFragment = { type: string; dateAchieved: any; goal: string };

export type BlindSpotTopicFragment = {
  id: string;
  topic?: { name?: string | null } | null;
  combinedTopic?: { name?: string | null } | null;
};

export type CheckAchievementsFragment = {
  aggregate: Array<{ achievementType: string; countTotal: number; countNew?: number | null; dateLast?: any | null }>;
  achievementsNew: Array<{ type: string; dateAchieved: any; goal: string }>;
};

export type EnvelopeSummaryFragment = { green: number; yellow: number; red: number };

export type LastAchievementFragment = { type: string; dateAchieved: any; goal: string };

export type OutageFragment = { startTime?: any | null; endTime?: any | null; text?: string | null };

export type SelfEvaluationFragment = {
  general?: { text?: string | null; image?: string | null } | null;
  options: Array<{ text?: string | null; image?: string | null; scoreReal?: number | null }>;
};

export type StudyDayFragment = {
  date?: any | null;
  startAt?: any | null;
  duration?: number | null;
  durationReal?: number | null;
  sessionFinished?: boolean | null;
  status?: string | null;
};

export type TopicWithUserAssignmentsFragment = {
  id: string;
  envelope?: string | null;
  assignment?: {
    id: string;
    isActive: boolean;
    assignment?: {
      assignmentText?: string | null;
      questionsText?: string | null;
      questions: Array<{ heading?: string | null }>;
    } | null;
    general?: { testOrder?: number | null; admissionTest: { name?: string | null } } | null;
  } | null;
};

export type UserAssignmentDetailFragment = {
  id: string;
  timeToSolve?: number | null;
  timeToLearn?: number | null;
  assignment?: {
    id: string;
    isActive: boolean;
    assignment?: {
      questionsText?: string | null;
      assignmentText?: string | null;
      assignmentImage?: string | null;
      printingAttachment?: string | null;
      isAnswerCaseSensitive?: boolean | null;
      assignmentType: { type?: string | null };
      questions: Array<{
        heading?: string | null;
        placeholder?: string | null;
        showAbilityScore?: boolean | null;
        options: Array<{ value?: string | null; additionalText?: string | null }>;
        solutionAnalysis?: {
          text?: string | null;
          image?: string | null;
          writtenVideo?: string | null;
          explainerVideo?: string | null;
        } | null;
        selfEvaluation?: {
          general?: { text?: string | null; image?: string | null } | null;
          options: Array<{ text?: string | null; image?: string | null; scoreReal?: number | null }>;
        } | null;
      }>;
    } | null;
    general?: { testOrder?: number | null; admissionTest: { name?: string | null } } | null;
    scoring?: {
      scoreMax?: number | null;
      scoringMethod: { scoringMethod?: string | null };
      selfEvaluation: { selfEvaluation?: string | null };
    } | null;
    analysis?: {
      hasSharedAnalysis?: boolean | null;
      sharedAnalysis?: {
        text?: string | null;
        image?: string | null;
        writtenVideo?: string | null;
        explainerVideo?: string | null;
      } | null;
    } | null;
    selfEvaluation?: {
      hasSharedSelfEvaluation: boolean;
      sharedSelfEvaluation?: {
        general?: { text?: string | null; image?: string | null } | null;
        options: Array<{ text?: string | null; image?: string | null; scoreReal?: number | null }>;
      } | null;
    } | null;
  } | null;
  topic?: { name?: string | null } | null;
  subject?: { appName?: string | null } | null;
};

export type UserAssignmentForExerciseFragment = {
  id: string;
  scoreMax?: number | null;
  timeToSolve?: number | null;
  timeToLearn?: number | null;
  envelope?: string | null;
  assignment?: {
    id: string;
    isActive: boolean;
    assignment?: {
      questionsText?: string | null;
      assignmentText?: string | null;
      assignmentImage?: string | null;
      printingAttachment?: string | null;
      isAnswerCaseSensitive?: boolean | null;
      questions: Array<{
        heading?: string | null;
        placeholder?: string | null;
        showAbilityScore?: boolean | null;
        options: Array<{ value?: string | null; additionalText?: string | null }>;
        selfEvaluation?: {
          general?: { text?: string | null; image?: string | null } | null;
          options: Array<{ text?: string | null; image?: string | null; scoreReal?: number | null }>;
        } | null;
        solutionAnalysis?: {
          text?: string | null;
          image?: string | null;
          writtenVideo?: string | null;
          explainerVideo?: string | null;
        } | null;
      }>;
      assignmentType: { type?: string | null };
    } | null;
    scoring?: {
      canTeacherEvaluate?: boolean | null;
      scoreMax?: number | null;
      timeToSolve?: number | null;
      scoringMethod: { scoringMethod?: string | null };
      selfEvaluation: { selfEvaluation?: string | null };
    } | null;
    analysis?: {
      hasSharedAnalysis?: boolean | null;
      sharedAnalysis?: {
        text?: string | null;
        image?: string | null;
        writtenVideo?: string | null;
        explainerVideo?: string | null;
      } | null;
    } | null;
    selfEvaluation?: {
      hasSharedSelfEvaluation: boolean;
      sharedSelfEvaluation?: {
        general?: { text?: string | null; image?: string | null } | null;
        options: Array<{ text?: string | null; image?: string | null; scoreReal?: number | null }>;
      } | null;
    } | null;
  } | null;
  topic?: { name?: string | null } | null;
  subject?: { appName?: string | null } | null;
};

export type UserAssignmentForExerciseMinimumFragment = {
  id: string;
  assignment?: { isActive: boolean; assignment?: { printingAttachment?: string | null } | null } | null;
  subject?: { appName?: string | null } | null;
};

export type UserAssignmentForExerciseMinimumMhdFragment = {
  id: string;
  assignment?: { isActive: boolean } | null;
  subject?: { appName?: string | null } | null;
};

export type UserAssignmentPracticeFragment = {
  id: string;
  timeToSolve?: number | null;
  timeToLearn?: number | null;
  assignment?: { isActive: boolean; assignment?: { printingAttachment?: string | null } | null } | null;
};

export type WorstTopicsFragment = {
  abilityScoreAll?: number | null;
  topic?: { name?: string | null; id: string } | null;
};

export type CheckAnswersMutationVariables = Exact<{
  assignmentId: Scalars["String"]["input"];
  timeToSolve: Scalars["Int"]["input"];
  userAnswers?: InputMaybe<Array<QuestionAutoInput> | QuestionAutoInput>;
  selfEvaluation?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type CheckAnswersMutation = { answer: { scoreReal: number } };

export type DeleteBookmarkMutationVariables = Exact<{
  bookmarkId: Scalars["String"]["input"];
}>;

export type DeleteBookmarkMutation = { deleteBookmark: { objectsDeleted: Array<string> } };

export type RemoveTopicFromBlackEnvelopeMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type RemoveTopicFromBlackEnvelopeMutation = { userTopicUpdate: { isBlindSpot: boolean } };

export type ResetOnboardingMutationVariables = Exact<{ [key: string]: never }>;

export type ResetOnboardingMutation = {
  updatePhone: { status: string };
  updateOnboarding: { introduced: boolean; finished: boolean };
};

export type SaveBookmarkMutationVariables = Exact<{
  assignmentId: Scalars["String"]["input"];
}>;

export type SaveBookmarkMutation = { setBookmark: { id: string } };

export type SendPhoneValidationCodeMutationVariables = Exact<{
  code: Scalars["String"]["input"];
}>;

export type SendPhoneValidationCodeMutation = { validatePhone: { status: string } };

export type SessionCloseMutationVariables = Exact<{
  mood: Scalars["Int"]["input"];
  moodText: Scalars["String"]["input"];
  assignmentsClose: Array<AssignmentCloseInput> | AssignmentCloseInput;
}>;

export type SessionCloseMutation = { sessionClose: { finished: boolean } };

export type SessionCreateMutationVariables = Exact<{
  sessionLength: Scalars["Int"]["input"];
}>;

export type SessionCreateMutation = {
  sessionCreate: {
    id: string;
    alreadyStarted: boolean;
    userAssignments: Array<{
      envelope?: string | null;
      assignment?: { assignment?: { printingAttachment?: string | null } | null } | null;
      subject?: { appName?: string | null } | null;
    }>;
  };
};

export type SessionStartMutationVariables = Exact<{ [key: string]: never }>;

export type SessionStartMutation = {
  sessionStart: {
    id: string;
    lengthActual: number;
    alreadyStarted: boolean;
    userAssignments: Array<{
      id: string;
      scoreMax?: number | null;
      timeToSolve?: number | null;
      timeToLearn?: number | null;
      envelope?: string | null;
      assignment?: {
        id: string;
        isActive: boolean;
        assignment?: {
          questionsText?: string | null;
          assignmentText?: string | null;
          assignmentImage?: string | null;
          printingAttachment?: string | null;
          isAnswerCaseSensitive?: boolean | null;
          questions: Array<{
            heading?: string | null;
            placeholder?: string | null;
            showAbilityScore?: boolean | null;
            options: Array<{ value?: string | null; additionalText?: string | null }>;
            selfEvaluation?: {
              general?: { text?: string | null; image?: string | null } | null;
              options: Array<{ text?: string | null; image?: string | null; scoreReal?: number | null }>;
            } | null;
            solutionAnalysis?: {
              text?: string | null;
              image?: string | null;
              writtenVideo?: string | null;
              explainerVideo?: string | null;
            } | null;
          }>;
          assignmentType: { type?: string | null };
        } | null;
        scoring?: {
          canTeacherEvaluate?: boolean | null;
          scoreMax?: number | null;
          timeToSolve?: number | null;
          scoringMethod: { scoringMethod?: string | null };
          selfEvaluation: { selfEvaluation?: string | null };
        } | null;
        analysis?: {
          hasSharedAnalysis?: boolean | null;
          sharedAnalysis?: {
            text?: string | null;
            image?: string | null;
            writtenVideo?: string | null;
            explainerVideo?: string | null;
          } | null;
        } | null;
        selfEvaluation?: {
          hasSharedSelfEvaluation: boolean;
          sharedSelfEvaluation?: {
            general?: { text?: string | null; image?: string | null } | null;
            options: Array<{ text?: string | null; image?: string | null; scoreReal?: number | null }>;
          } | null;
        } | null;
      } | null;
      topic?: { name?: string | null } | null;
      subject?: { appName?: string | null } | null;
    }>;
  };
};

export type SetTimetableGenericMutationVariables = Exact<{
  from: Scalars["Date"]["input"];
  to: Scalars["Date"]["input"];
  timeTable: Array<StudyDayGenericInput> | StudyDayGenericInput;
}>;

export type SetTimetableGenericMutation = {
  setTimetableGeneric: { plan: Array<{ dayName: string; startAt?: any | null; duration?: number | null }> };
};

export type SetOnboardingFinishedMutationVariables = Exact<{ [key: string]: never }>;

export type SetOnboardingFinishedMutation = { updateOnboarding: { finished: boolean } };

export type SetOnboardingIntroducedMutationVariables = Exact<{ [key: string]: never }>;

export type SetOnboardingIntroducedMutation = { updateOnboarding: { introduced: boolean } };

export type SetTimetableMutationVariables = Exact<{
  timeTable: Array<StudyDayInput> | StudyDayInput;
}>;

export type SetTimetableMutation = {
  setTimetable: {
    dateStart?: any | null;
    dateEnd?: any | null;
    plan: Array<{ dayIndex?: number | null; startAt?: any | null; duration?: number | null }>;
  };
};

export type SetWelcomedMutationVariables = Exact<{ [key: string]: never }>;

export type SetWelcomedMutation = { updateOnboarding: { welcome: boolean } };

export type SolutionUploadConfirmMutationVariables = Exact<{
  solutionUploadId: Scalars["String"]["input"];
}>;

export type SolutionUploadConfirmMutation = { solutionUploadConfirm?: any | null };

export type SolutionUploadUrlMutationVariables = Exact<{
  userAssignmentId: Scalars["String"]["input"];
  contentType: Scalars["String"]["input"];
  fileExtension: Scalars["String"]["input"];
  fileSize: Scalars["Int"]["input"];
}>;

export type SolutionUploadUrlMutation = { solutionUploadUrl: { command: string; uploadId: string; url: string } };

export type UpdateParentRewardsMutationVariables = Exact<{
  rewards: ParentWatchInput;
}>;

export type UpdateParentRewardsMutation = {
  parentWatchUpdate: {
    message?: string | null;
    rewards: Array<{ type: string; targetScore?: number | null; month?: number | null; text: string }>;
  };
};

export type UpdatePhoneNumberMutationVariables = Exact<{
  phoneNumber: Scalars["String"]["input"];
  smsEnabled: Scalars["Boolean"]["input"];
}>;

export type UpdatePhoneNumberMutation = { updatePhone: { status: string; datetimeLast?: string | null } };

export type UpdateSoundMutationVariables = Exact<{
  withSound: Scalars["Boolean"]["input"];
}>;

export type UpdateSoundMutation = { updateUser: { withSound?: boolean | null } };

export type UpdateUserPreferencesMutationVariables = Exact<{
  phoneNumber: Scalars["String"]["input"];
  preferredSubject: Scalars["String"]["input"];
  smsEnabled: Scalars["Boolean"]["input"];
}>;

export type UpdateUserPreferencesMutation = {
  updateUser: { preferredSubject?: string | null };
  updatePhone: { status: string; datetimeLast?: string | null };
};

export type UserLoginMutationVariables = Exact<{
  token: Scalars["String"]["input"];
}>;

export type UserLoginMutation = { login: { status: string; accessToken?: string | null } };

export type ValidateAnswerMutationVariables = Exact<{
  userAssignmentId: Scalars["String"]["input"];
  questions: Array<Scalars["Boolean"]["input"]> | Scalars["Boolean"]["input"];
}>;

export type ValidateAnswerMutation = { claimEvaluation: { scoreReal: number } };

export type CheckAchievementsQueryVariables = Exact<{ [key: string]: never }>;

export type CheckAchievementsQuery = {
  checkAchievements: {
    aggregate: Array<{ achievementType: string; countTotal: number; countNew?: number | null; dateLast?: any | null }>;
    achievementsNew: Array<{ type: string; dateAchieved: any; goal: string }>;
  };
};

export type GetAllBookmarksQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllBookmarksQuery = { bookmarks: Array<{ id: string; userAssignment: { id: string } }> };

export type GetAllBookmarksWithSubjectQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllBookmarksWithSubjectQuery = {
  bookmarks: Array<{ id: string; userAssignment: { id: string; subject?: { appName?: string | null } | null } }>;
};

export type GetAllTimetableGenericsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllTimetableGenericsQuery = { timetableGenericAll: Array<{ created: any }> };

export type GetAssignmentAnswersQueryVariables = Exact<{
  assignmentId: Scalars["String"]["input"];
}>;

export type GetAssignmentAnswersQuery = {
  assignments: Array<{ assignment?: { questions: Array<{ correctAnswers: Array<string | null> }> } | null }>;
};

export type GetAssignmentDetailForCmsQueryVariables = Exact<{
  assignmentId: Scalars["String"]["input"];
}>;

export type GetAssignmentDetailForCmsQuery = {
  assignments: Array<{
    id: string;
    isActive: boolean;
    assignment?: {
      questionsText?: string | null;
      assignmentText?: string | null;
      assignmentImage?: string | null;
      printingAttachment?: string | null;
      isAnswerCaseSensitive?: boolean | null;
      assignmentType: { type?: string | null };
      questions: Array<{
        heading?: string | null;
        placeholder?: string | null;
        showAbilityScore?: boolean | null;
        options: Array<{ value?: string | null; additionalText?: string | null }>;
        solutionAnalysis?: {
          text?: string | null;
          image?: string | null;
          writtenVideo?: string | null;
          explainerVideo?: string | null;
        } | null;
        selfEvaluation?: {
          general?: { text?: string | null; image?: string | null } | null;
          options: Array<{ text?: string | null; image?: string | null; scoreReal?: number | null }>;
        } | null;
      }>;
    } | null;
    general?: { testOrder?: number | null; admissionTest: { name?: string | null } } | null;
    scoring?: {
      canTeacherEvaluate?: boolean | null;
      scoringMethod: { scoringMethod?: string | null };
      selfEvaluation: { selfEvaluation?: string | null };
    } | null;
    analysis?: {
      hasSharedAnalysis?: boolean | null;
      sharedAnalysis?: {
        text?: string | null;
        image?: string | null;
        writtenVideo?: string | null;
        explainerVideo?: string | null;
      } | null;
    } | null;
    selfEvaluation?: {
      hasSharedSelfEvaluation: boolean;
      sharedSelfEvaluation?: {
        general?: { text?: string | null; image?: string | null } | null;
        options: Array<{ text?: string | null; image?: string | null; scoreReal?: number | null }>;
      } | null;
    } | null;
  }>;
};

export type GetAssignmentsEnvelopesQueryVariables = Exact<{
  assignments?: InputMaybe<Array<Scalars["String"]["input"]> | Scalars["String"]["input"]>;
}>;

export type GetAssignmentsEnvelopesQuery = {
  userAssignments: { items: Array<{ envelope?: string | null; id: string }> };
};

export type GetAssignmentsInTopicQueryVariables = Exact<{
  combinedId: Scalars["String"]["input"];
}>;

export type GetAssignmentsInTopicQuery = { userAssignments: { items: Array<{ id: string }> } };

export type GetBookmarkedPracticeAssignmentsQueryVariables = Exact<{ [key: string]: never }>;

export type GetBookmarkedPracticeAssignmentsQuery = {
  bookmarks: Array<{
    id: string;
    userAssignment: {
      id: string;
      timeToSolve?: number | null;
      timeToLearn?: number | null;
      assignment?: { isActive: boolean; assignment?: { printingAttachment?: string | null } | null } | null;
    };
  }>;
};

export type GetBookmarksAndSubjectsQueryVariables = Exact<{ [key: string]: never }>;

export type GetBookmarksAndSubjectsQuery = {
  bookmarks: Array<{
    userAssignment: {
      id: string;
      envelope?: string | null;
      assignment?: {
        id: string;
        isActive: boolean;
        assignment?: {
          assignmentText?: string | null;
          questionsText?: string | null;
          questions: Array<{ heading?: string | null }>;
        } | null;
        general?: { testOrder?: number | null; admissionTest: { name?: string | null } } | null;
      } | null;
      subject?: { appName?: string | null } | null;
    };
  }>;
  subjects: Array<{ appName?: string | null }>;
};

export type GetBookmarksCountQueryVariables = Exact<{ [key: string]: never }>;

export type GetBookmarksCountQuery = { bookmarks: Array<{ id: string }> };

export type GetCombinedTopicAssignmentsQueryVariables = Exact<{
  combinedTopicId: Scalars["String"]["input"];
}>;

export type GetCombinedTopicAssignmentsQuery = {
  combinedTopicsCached: Array<{ id: string; name?: string | null }>;
  userAssignmentsCached: {
    items: Array<{
      id: string;
      envelope?: string | null;
      assignment?: {
        id: string;
        isActive: boolean;
        assignment?: {
          assignmentText?: string | null;
          questionsText?: string | null;
          questions: Array<{ heading?: string | null }>;
        } | null;
        general?: { testOrder?: number | null; admissionTest: { name?: string | null } } | null;
      } | null;
    }>;
  };
};

export type GetCombinedTopicTopicsQueryVariables = Exact<{
  combinedTopicId: Scalars["String"]["input"];
}>;

export type GetCombinedTopicTopicsQuery = {
  combinedTopics: Array<{ id: string; name?: string | null; topics: Array<{ id: string; name?: string | null }> }>;
  userTopics: Array<{
    scoreAggregatePercent?: number | null;
    assignmentsGreen: number;
    assignmentsRed: number;
    assignmentsYellow: number;
    topic?: { id: string } | null;
  }>;
  userAssignments: { totalCount: number };
};

export type GetCombinedTopicPracticeAssignmentsQueryVariables = Exact<{
  combinedTopicId: Scalars["String"]["input"];
}>;

export type GetCombinedTopicPracticeAssignmentsQuery = {
  userAssignments: {
    items: Array<{
      id: string;
      timeToSolve?: number | null;
      timeToLearn?: number | null;
      assignment?: { isActive: boolean; assignment?: { printingAttachment?: string | null } | null } | null;
    }>;
  };
  combinedTopics: Array<{ name?: string | null }>;
};

export type GetAssignmentsInEnvelopeQueryVariables = Exact<{
  combinedId: Scalars["String"]["input"];
  envelopeId: Scalars["String"]["input"];
}>;

export type GetAssignmentsInEnvelopeQuery = { userAssignments: { items: Array<{ id: string }> } };

export type GetCurrentGenericTimetableQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentGenericTimetableQuery = { timetableGenericCurrent: { created: any } };

export type GetCurrentSessionCountQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentSessionCountQuery = { currentUser: { sessionsAchieved?: number | null } };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQuery = {
  currentUser: {
    admissionsDeadline?: any | null;
    email?: string | null;
    name?: string | null;
    vocative?: string | null;
    grade?: string | null;
    sex?: string | null;
    testsToUnlock?: number | null;
    testsToBuy?: number | null;
    preferredSubject?: string | null;
    withSound?: boolean | null;
    phoneNumber?: string | null;
    phoneValidationDatetimeLast?: string | null;
    phoneValidationPhoneNumber?: string | null;
    firstLogin?: any | null;
    sessionsAchieved?: number | null;
    phoneValidationStatus?: string | null;
    smsEnabled?: boolean | null;
    elixirOfLifeCount?: number | null;
    daysFulfillsPlan?: number | null;
    idExternalZona?: string | null;
    preferredSchools: Array<{ name?: string | null; pointsForAcceptance?: number | null }>;
    onboarding: { introduced: boolean; finished: boolean; welcome: boolean };
  };
  timetableGenericAll: Array<{ created: any }>;
};

export type GetDashboardDataQueryVariables = Exact<{
  timetableFrom: Scalars["Date"]["input"];
  timetableTo: Scalars["Date"]["input"];
}>;

export type GetDashboardDataQuery = {
  displayOutages: Array<{ startTime?: any | null; endTime?: any | null; text?: string | null }>;
  timetable: {
    timetable: Array<{
      dateStart?: any | null;
      dateEnd?: any | null;
      plan: Array<{
        date?: any | null;
        startAt?: any | null;
        duration?: number | null;
        durationReal?: number | null;
        sessionFinished?: boolean | null;
        status?: string | null;
      }>;
    }>;
  };
  testPresence: Array<{ dateTime: string }>;
  envelopesSummary: { green: number; yellow: number; red: number };
  blindSpotTopics: Array<{
    id: string;
    topic?: { name?: string | null } | null;
    combinedTopic?: { name?: string | null } | null;
  }>;
  lastAchievements: Array<{ type: string; dateAchieved: any; goal: string }>;
  checkAchievements: {
    aggregate: Array<{ achievementType: string; countTotal: number; countNew?: number | null; dateLast?: any | null }>;
    achievementsNew: Array<{ type: string; dateAchieved: any; goal: string }>;
  };
  userTopicsWorst: Array<{ abilityScoreAll?: number | null; topic?: { name?: string | null; id: string } | null }>;
};

export type GetEnvelopeCombinedTopicAssignmentsQueryVariables = Exact<{
  combinedTopicId: Scalars["String"]["input"];
  envelopeId: Scalars["String"]["input"];
}>;

export type GetEnvelopeCombinedTopicAssignmentsQuery = {
  combinedTopics: Array<{ id: string; name?: string | null }>;
  userAssignments: {
    items: Array<{
      id: string;
      envelope?: string | null;
      assignment?: {
        id: string;
        isActive: boolean;
        assignment?: {
          assignmentText?: string | null;
          questionsText?: string | null;
          questions: Array<{ heading?: string | null }>;
        } | null;
        general?: { testOrder?: number | null; admissionTest: { name?: string | null } } | null;
      } | null;
    }>;
  };
};

export type GetEnvelopeCombinedTopicTopicsQueryVariables = Exact<{
  combinedTopicId: Scalars["String"]["input"];
  envelopeId: Scalars["String"]["input"];
  isRed: Scalars["Boolean"]["input"];
  isGreen: Scalars["Boolean"]["input"];
  isOrange: Scalars["Boolean"]["input"];
}>;

export type GetEnvelopeCombinedTopicTopicsQuery = {
  combinedTopics: Array<{ id: string; name?: string | null; topics: Array<{ id: string; name?: string | null }> }>;
  userTopics: Array<{
    scoreAggregatePercent?: number | null;
    hasAssignmentsGreen?: boolean;
    hasAssignmentsRed: boolean;
    hasAssignmentsYellow: boolean;
    topic?: { id: string } | null;
  }>;
  userAssignments: { totalCount: number };
};

export type GetEnvelopeCombinedTopicPracticeAssignmentsQueryVariables = Exact<{
  envelopeId: Scalars["String"]["input"];
  combinedTopicId: Scalars["String"]["input"];
}>;

export type GetEnvelopeCombinedTopicPracticeAssignmentsQuery = {
  userAssignments: {
    items: Array<{
      id: string;
      timeToSolve?: number | null;
      timeToLearn?: number | null;
      assignment?: { isActive: boolean; assignment?: { printingAttachment?: string | null } | null } | null;
    }>;
  };
  combinedTopics: Array<{ name?: string | null }>;
};

export type GetEnvelopeSubjectsTopicsQueryVariables = Exact<{
  isRed: Scalars["Boolean"]["input"];
  isGreen: Scalars["Boolean"]["input"];
  isOrange: Scalars["Boolean"]["input"];
}>;

export type GetEnvelopeSubjectsTopicsQuery = {
  subjects: Array<{
    id: string;
    appAbbr?: string | null;
    appName?: string | null;
    isFavorite?: boolean | null;
    superTopics: Array<{ id: string; name?: string | null }>;
  }>;
  userSubjects: Array<{
    scoreAggregatePercent?: number | null;
    assignmentsGreen: number;
    assignmentsYellow: number;
    assignmentsRed: number;
    subject?: { id: string } | null;
  }>;
  userSuperTopics: Array<{
    scoreAggregatePercent?: number | null;
    assignmentsGreen: number;
    assignmentsYellow: number;
    assignmentsRed: number;
    superTopic?: { id: string } | null;
  }>;
  envelopesSummary: { green: number; yellow: number; red: number };
};

export type GetEnvelopeSuperTopicPracticeAssignmentsQueryVariables = Exact<{
  envelopeId: Scalars["String"]["input"];
  superTopicId: Scalars["String"]["input"];
}>;

export type GetEnvelopeSuperTopicPracticeAssignmentsQuery = {
  userAssignments: {
    items: Array<{
      id: string;
      timeToSolve?: number | null;
      timeToLearn?: number | null;
      assignment?: { isActive: boolean; assignment?: { printingAttachment?: string | null } | null } | null;
    }>;
  };
  superTopics: Array<{ name?: string | null }>;
};

export type GetEnvelopeSuperTopicTopicsQueryVariables = Exact<{
  superTopicId: Scalars["String"]["input"];
  envelopeId: Scalars["String"]["input"];
  isRed: Scalars["Boolean"]["input"];
  isGreen: Scalars["Boolean"]["input"];
  isOrange: Scalars["Boolean"]["input"];
}>;

export type GetEnvelopeSuperTopicTopicsQuery = {
  superTopics: Array<{ id: string; name?: string | null; combinedTopics: Array<{ id: string; name?: string | null }> }>;
  userCombinedTopics: Array<{
    hasAssignments: boolean;
    assignmentsGreen: number;
    assignmentsRed: number;
    assignmentsYellow: number;
    combinedTopic?: { id: string } | null;
  }>;
  userAssignments: { totalCount: number };
};

export type GetEnvelopeTopicAssignmentsQueryVariables = Exact<{
  topicId: Scalars["String"]["input"];
  envelopeId: Scalars["String"]["input"];
}>;

export type GetEnvelopeTopicAssignmentsQuery = {
  userAssignments: {
    totalCount: number;
    items: Array<{
      id: string;
      envelope?: string | null;
      assignment?: {
        id: string;
        isActive: boolean;
        assignment?: {
          assignmentText?: string | null;
          questionsText?: string | null;
          questions: Array<{ heading?: string | null }>;
        } | null;
        general?: { testOrder?: number | null; admissionTest: { name?: string | null } } | null;
      } | null;
    }>;
  };
  topics: Array<{ name?: string | null }>;
};

export type GetEnvelopeTopicPracticeAssignmentsQueryVariables = Exact<{
  envelopeId: Scalars["String"]["input"];
  topicId: Scalars["String"]["input"];
}>;

export type GetEnvelopeTopicPracticeAssignmentsQuery = {
  userAssignments: {
    totalCount: number;
    items: Array<{
      id: string;
      timeToSolve?: number | null;
      timeToLearn?: number | null;
      assignment?: { isActive: boolean; assignment?: { printingAttachment?: string | null } | null } | null;
    }>;
  };
  topics: Array<{ name?: string | null }>;
};

export type GetFaqQueryVariables = Exact<{ [key: string]: never }>;

export type GetFaqQuery = { faqAll: Array<{ title?: string | null; description?: string | null }> };

export type GetOnboardingAssignmentsQueryVariables = Exact<{ [key: string]: never }>;

export type GetOnboardingAssignmentsQuery = {
  userAssignments: {
    items: Array<{
      id: string;
      scoreMax?: number | null;
      timeToSolve?: number | null;
      timeToLearn?: number | null;
      envelope?: string | null;
      assignment?: {
        id: string;
        isActive: boolean;
        assignment?: {
          questionsText?: string | null;
          assignmentText?: string | null;
          assignmentImage?: string | null;
          printingAttachment?: string | null;
          isAnswerCaseSensitive?: boolean | null;
          questions: Array<{
            heading?: string | null;
            placeholder?: string | null;
            showAbilityScore?: boolean | null;
            options: Array<{ value?: string | null; additionalText?: string | null }>;
            selfEvaluation?: {
              general?: { text?: string | null; image?: string | null } | null;
              options: Array<{ text?: string | null; image?: string | null; scoreReal?: number | null }>;
            } | null;
            solutionAnalysis?: {
              text?: string | null;
              image?: string | null;
              writtenVideo?: string | null;
              explainerVideo?: string | null;
            } | null;
          }>;
          assignmentType: { type?: string | null };
        } | null;
        scoring?: {
          canTeacherEvaluate?: boolean | null;
          scoreMax?: number | null;
          timeToSolve?: number | null;
          scoringMethod: { scoringMethod?: string | null };
          selfEvaluation: { selfEvaluation?: string | null };
        } | null;
        analysis?: {
          hasSharedAnalysis?: boolean | null;
          sharedAnalysis?: {
            text?: string | null;
            image?: string | null;
            writtenVideo?: string | null;
            explainerVideo?: string | null;
          } | null;
        } | null;
        selfEvaluation?: {
          hasSharedSelfEvaluation: boolean;
          sharedSelfEvaluation?: {
            general?: { text?: string | null; image?: string | null } | null;
            options: Array<{ text?: string | null; image?: string | null; scoreReal?: number | null }>;
          } | null;
        } | null;
      } | null;
      topic?: { name?: string | null } | null;
      subject?: { appName?: string | null } | null;
    }>;
  };
};

export type GetOnboardingStatusQueryVariables = Exact<{ [key: string]: never }>;

export type GetOnboardingStatusQuery = { onboarding: { finished: boolean } };

export type GetParentRewardsQueryVariables = Exact<{ [key: string]: never }>;

export type GetParentRewardsQuery = {
  parentWatch: {
    message?: string | null;
    rewards: Array<{ type: string; targetScore?: number | null; month?: number | null; text: string }>;
    user: { name?: string | null; admissionsDeadline?: any | null };
  };
};

export type GetPracticeEnvelopeQueryVariables = Exact<{
  envelopeId: Scalars["String"]["input"];
}>;

export type GetPracticeEnvelopeQuery = {
  trainingEnvelope: Array<{
    id: string;
    timeToSolve?: number | null;
    timeToLearn?: number | null;
    assignment?: { isActive: boolean; assignment?: { printingAttachment?: string | null } | null } | null;
  }>;
};

export type GetPracticeMhdQueryVariables = Exact<{
  length: Scalars["Int"]["input"];
}>;

export type GetPracticeMhdQuery = {
  trainingMhd: {
    userAssignments: Array<{
      id: string;
      assignment?: { isActive: boolean } | null;
      subject?: { appName?: string | null } | null;
    }>;
  };
};

export type GetPracticeMixQueryVariables = Exact<{
  length: Scalars["Int"]["input"];
}>;

export type GetPracticeMixQuery = {
  training: {
    userAssignments: Array<{
      id: string;
      assignment?: { isActive: boolean; assignment?: { printingAttachment?: string | null } | null } | null;
      subject?: { appName?: string | null } | null;
    }>;
  };
};

export type GetSessionResultsQueryVariables = Exact<{
  dateFrom: Scalars["Date"]["input"];
}>;

export type GetSessionResultsQuery = {
  userSessionReview: Array<{ dateTested: any; scoreMaxTotal?: number | null; scoreRealTotal: number }>;
};

export type GetStatisticsDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetStatisticsDataQuery = {
  checkAchievements: { achievements: Array<{ type: string; dateAchieved: any; goal: string }> };
  parentWatch: {
    message?: string | null;
    rewards: Array<{ targetScore?: number | null; month?: number | null; text: string }>;
  };
};

export type GetStreakQueryVariables = Exact<{
  streakFrom: Scalars["Date"]["input"];
  streakTo: Scalars["Date"]["input"];
}>;

export type GetStreakQuery = { timetable: { daysFulfillsPlan: number } };

export type GetSubjectsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSubjectsQuery = {
  subjects: Array<{ appAbbr?: string | null; appName?: string | null; isFavorite?: boolean | null }>;
};

export type GetSubjectsTopicsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSubjectsTopicsQuery = {
  subjectsCached: Array<{
    id: string;
    appAbbr?: string | null;
    appName?: string | null;
    isFavorite?: boolean | null;
    superTopics: Array<{ id: string; name?: string | null }>;
  }>;
  bookmarksCached: Array<{ id: string }>;
  userSubjectsCached: Array<{ scoreAggregatePercent?: number | null; subject?: { id: string } | null }>;
  userSuperTopicsCached: Array<{
    scoreAggregatePercent?: number | null;
    hasAssignments: boolean;
    superTopic?: { id: string } | null;
  }>;
};

export type GetSuperTopicPracticeAssignmentsQueryVariables = Exact<{
  superTopicId: Scalars["String"]["input"];
}>;

export type GetSuperTopicPracticeAssignmentsQuery = {
  userAssignments: {
    items: Array<{
      id: string;
      timeToSolve?: number | null;
      timeToLearn?: number | null;
      assignment?: { isActive: boolean; assignment?: { printingAttachment?: string | null } | null } | null;
    }>;
  };
  superTopics: Array<{ name?: string | null }>;
};

export type GetSuperTopicTopicsQueryVariables = Exact<{
  superTopicId: Scalars["String"]["input"];
}>;

export type GetSuperTopicTopicsQuery = {
  superTopicsCached: Array<{
    id: string;
    name?: string | null;
    combinedTopics: Array<{ id: string; name?: string | null }>;
  }>;
  userCombinedTopicsCached: Array<{
    scoreAggregatePercent?: number | null;
    hasAssignments: boolean;
    assignmentsRed: number;
    assignmentsYellow: number;
    assignmentsGreen: number;
    combinedTopic?: { id: string } | null;
  }>;
  userAssignmentsCached: { totalCount: number };
};

export type GetTestPresencesQueryVariables = Exact<{ [key: string]: never }>;

export type GetTestPresencesQuery = {
  testPresence: Array<{
    dateTime: string;
    scoreMath?: number | null;
    scoreCzech?: number | null;
    absolved?: boolean | null;
    form?: string | null;
  }>;
};

export type GetTimetableQueryVariables = Exact<{
  dateFrom: Scalars["Date"]["input"];
  dateTo: Scalars["Date"]["input"];
}>;

export type GetTimetableQuery = {
  timetable: {
    timetable: Array<{
      dateStart?: any | null;
      dateEnd?: any | null;
      plan: Array<{
        date?: any | null;
        startAt?: any | null;
        duration?: number | null;
        durationReal?: number | null;
        sessionFinished?: boolean | null;
        status?: string | null;
      }>;
    }>;
  };
  testPresence: Array<{ dateTime: string }>;
};

export type GetTimetableGenericQueryVariables = Exact<{ [key: string]: never }>;

export type GetTimetableGenericQuery = {
  timetableGenericCurrent: { plan: Array<{ dayName: string; startAt?: any | null; duration?: number | null }> };
};

export type GetTopicAssignmentsQueryVariables = Exact<{
  topicId: Scalars["String"]["input"];
}>;

export type GetTopicAssignmentsQuery = {
  userAssignments: {
    totalCount: number;
    items: Array<{
      id: string;
      envelope?: string | null;
      assignment?: {
        id: string;
        isActive: boolean;
        assignment?: {
          assignmentText?: string | null;
          questionsText?: string | null;
          questions: Array<{ heading?: string | null }>;
        } | null;
        general?: { testOrder?: number | null; admissionTest: { name?: string | null } } | null;
      } | null;
    }>;
  };
  topics: Array<{ name?: string | null }>;
};

export type GetTopicAssignmentsListQueryVariables = Exact<{
  topicId: Scalars["String"]["input"];
}>;

export type GetTopicAssignmentsListQuery = {
  userAssignments: {
    items: Array<{
      id: string;
      timeToSolve?: number | null;
      timeToLearn?: number | null;
      assignment?: { isActive: boolean; assignment?: { printingAttachment?: string | null } | null } | null;
    }>;
  };
  topics: Array<{ name?: string | null }>;
};

export type GetTopicPracticeAssignmentsQueryVariables = Exact<{
  topicId: Scalars["String"]["input"];
}>;

export type GetTopicPracticeAssignmentsQuery = {
  userAssignments: {
    items: Array<{
      id: string;
      timeToSolve?: number | null;
      timeToLearn?: number | null;
      assignment?: { isActive: boolean; assignment?: { printingAttachment?: string | null } | null } | null;
    }>;
  };
  topics: Array<{ name?: string | null }>;
};

export type GetUserAssignmentQueryVariables = Exact<{
  assignmentId: Scalars["String"]["input"];
}>;

export type GetUserAssignmentQuery = {
  userAssignments: {
    items: Array<{
      id: string;
      timeToSolve?: number | null;
      timeToLearn?: number | null;
      assignment?: {
        id: string;
        isActive: boolean;
        assignment?: {
          questionsText?: string | null;
          assignmentText?: string | null;
          assignmentImage?: string | null;
          printingAttachment?: string | null;
          isAnswerCaseSensitive?: boolean | null;
          assignmentType: { type?: string | null };
          questions: Array<{
            heading?: string | null;
            placeholder?: string | null;
            showAbilityScore?: boolean | null;
            options: Array<{ value?: string | null; additionalText?: string | null }>;
            solutionAnalysis?: {
              text?: string | null;
              image?: string | null;
              writtenVideo?: string | null;
              explainerVideo?: string | null;
            } | null;
            selfEvaluation?: {
              general?: { text?: string | null; image?: string | null } | null;
              options: Array<{ text?: string | null; image?: string | null; scoreReal?: number | null }>;
            } | null;
          }>;
        } | null;
        general?: { testOrder?: number | null; admissionTest: { name?: string | null } } | null;
        scoring?: {
          scoreMax?: number | null;
          scoringMethod: { scoringMethod?: string | null };
          selfEvaluation: { selfEvaluation?: string | null };
        } | null;
        analysis?: {
          hasSharedAnalysis?: boolean | null;
          sharedAnalysis?: {
            text?: string | null;
            image?: string | null;
            writtenVideo?: string | null;
            explainerVideo?: string | null;
          } | null;
        } | null;
        selfEvaluation?: {
          hasSharedSelfEvaluation: boolean;
          sharedSelfEvaluation?: {
            general?: { text?: string | null; image?: string | null } | null;
            options: Array<{ text?: string | null; image?: string | null; scoreReal?: number | null }>;
          } | null;
        } | null;
      } | null;
      topic?: { name?: string | null } | null;
      subject?: { appName?: string | null } | null;
    }>;
  };
};

export type GetUserAssignmentPaginatedQueryVariables = Exact<{
  assignmentIds: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type GetUserAssignmentPaginatedQuery = {
  userAssignments: {
    items: Array<{
      id: string;
      timeToSolve?: number | null;
      timeToLearn?: number | null;
      assignment?: {
        id: string;
        isActive: boolean;
        assignment?: {
          questionsText?: string | null;
          assignmentText?: string | null;
          assignmentImage?: string | null;
          printingAttachment?: string | null;
          isAnswerCaseSensitive?: boolean | null;
          assignmentType: { type?: string | null };
          questions: Array<{
            heading?: string | null;
            placeholder?: string | null;
            showAbilityScore?: boolean | null;
            options: Array<{ value?: string | null; additionalText?: string | null }>;
            solutionAnalysis?: {
              text?: string | null;
              image?: string | null;
              writtenVideo?: string | null;
              explainerVideo?: string | null;
            } | null;
            selfEvaluation?: {
              general?: { text?: string | null; image?: string | null } | null;
              options: Array<{ text?: string | null; image?: string | null; scoreReal?: number | null }>;
            } | null;
          }>;
        } | null;
        general?: { testOrder?: number | null; admissionTest: { name?: string | null } } | null;
        scoring?: {
          scoreMax?: number | null;
          scoringMethod: { scoringMethod?: string | null };
          selfEvaluation: { selfEvaluation?: string | null };
        } | null;
        analysis?: {
          hasSharedAnalysis?: boolean | null;
          sharedAnalysis?: {
            text?: string | null;
            image?: string | null;
            writtenVideo?: string | null;
            explainerVideo?: string | null;
          } | null;
        } | null;
        selfEvaluation?: {
          hasSharedSelfEvaluation: boolean;
          sharedSelfEvaluation?: {
            general?: { text?: string | null; image?: string | null } | null;
            options: Array<{ text?: string | null; image?: string | null; scoreReal?: number | null }>;
          } | null;
        } | null;
      } | null;
      topic?: { name?: string | null } | null;
      subject?: { appName?: string | null } | null;
    }>;
  };
};

export type SearchAssignmentsQueryVariables = Exact<{
  search: Scalars["String"]["input"];
}>;

export type SearchAssignmentsQuery = {
  userAssignmentsFulltext: {
    totalCount: number;
    items: Array<{
      id: string;
      envelope?: string | null;
      assignment?: {
        id: string;
        isActive: boolean;
        assignment?: {
          assignmentText?: string | null;
          questionsText?: string | null;
          questions: Array<{ heading?: string | null }>;
        } | null;
        general?: { testOrder?: number | null; admissionTest: { name?: string | null } } | null;
      } | null;
    }>;
  };
};

export const AssignmentCombinedTopicHierarchyFragmentDoc = `
    fragment assignmentCombinedTopicHierarchy on UserAssignmentType {
  combinedTopic {
    id
  }
}
    `;
export const SelfEvaluationFragmentDoc = `
    fragment selfEvaluation on CommonSelfEvaluationType {
  general {
    text
    image
  }
  options {
    text
    image
    scoreReal
  }
}
    `;
export const AssignmentDetailFragmentDoc = `
    fragment assignmentDetail on AssignmentType {
  id
  isActive
  assignment {
    questionsText
    assignmentText
    assignmentImage
    printingAttachment
    isAnswerCaseSensitive
    assignmentType {
      type
    }
    questions {
      heading
      placeholder
      options {
        value
        additionalText
      }
      showAbilityScore
      solutionAnalysis {
        text
        image
        writtenVideo
        explainerVideo
      }
      selfEvaluation {
        ...selfEvaluation
      }
    }
  }
  general {
    testOrder
    admissionTest {
      name
    }
  }
  scoring {
    canTeacherEvaluate
    scoringMethod {
      scoringMethod
    }
    selfEvaluation {
      selfEvaluation
    }
  }
  analysis {
    hasSharedAnalysis
    sharedAnalysis {
      text
      image
      writtenVideo
      explainerVideo
    }
  }
  selfEvaluation {
    hasSharedSelfEvaluation
    sharedSelfEvaluation {
      ...selfEvaluation
    }
  }
}
    ${SelfEvaluationFragmentDoc}`;
export const AssignmentDetailForBookmarkFragmentDoc = `
    fragment assignmentDetailForBookmark on AssignmentType {
  id
  isActive
  assignment {
    assignmentText
    questionsText
    questions {
      heading
    }
  }
  general {
    testOrder
    admissionTest {
      name
    }
  }
}
    `;
export const AssignmentForBookmarkFragmentDoc = `
    fragment assignmentForBookmark on BookmarkType {
  userAssignment {
    id
    assignment {
      ...assignmentDetailForBookmark
    }
    envelope
    subject {
      appName
    }
  }
}
    ${AssignmentDetailForBookmarkFragmentDoc}`;
export const AssignmentSuperTopicHierarchyFragmentDoc = `
    fragment assignmentSuperTopicHierarchy on UserAssignmentType {
  superTopic {
    id
  }
  subject {
    id
  }
}
    `;
export const BadgeFragmentDoc = `
    fragment badge on UserAchievementType {
  type
  dateAchieved
  goal
}
    `;
export const BlindSpotTopicFragmentDoc = `
    fragment blindSpotTopic on UserTopicType {
  id
  topic {
    name
  }
  combinedTopic {
    name
  }
}
    `;
export const CheckAchievementsFragmentDoc = `
    fragment checkAchievements on AchievementsType {
  aggregate {
    achievementType
    countTotal
    countNew
    dateLast
  }
  achievementsNew {
    type
    dateAchieved
    goal
  }
}
    `;
export const EnvelopeSummaryFragmentDoc = `
    fragment envelopeSummary on EnvelopesSummaryType {
  green
  yellow
  red
}
    `;
export const LastAchievementFragmentDoc = `
    fragment lastAchievement on UserAchievementType {
  type
  dateAchieved
  goal
}
    `;
export const OutageFragmentDoc = `
    fragment outage on OutageType {
  startTime
  endTime
  text
}
    `;
export const StudyDayFragmentDoc = `
    fragment studyDay on StudyDayType {
  date
  startAt
  duration
  durationReal
  sessionFinished
  status
}
    `;
export const AssignmentForTopicFragmentDoc = `
    fragment assignmentForTopic on AssignmentType {
  id
  isActive
  assignment {
    assignmentText
    questionsText
    questions {
      heading
    }
  }
  general {
    testOrder
    admissionTest {
      name
    }
  }
}
    `;
export const TopicWithUserAssignmentsFragmentDoc = `
    fragment topicWithUserAssignments on UserAssignmentType {
  id
  assignment {
    ...assignmentForTopic
  }
  envelope
}
    ${AssignmentForTopicFragmentDoc}`;
export const AssignmentPracticeFragmentDoc = `
    fragment assignmentPractice on AssignmentType {
  id
  isActive
  assignment {
    questionsText
    assignmentText
    assignmentImage
    printingAttachment
    isAnswerCaseSensitive
    assignmentType {
      type
    }
    questions {
      heading
      placeholder
      options {
        value
        additionalText
      }
      showAbilityScore
      solutionAnalysis {
        text
        image
        writtenVideo
        explainerVideo
      }
      selfEvaluation {
        ...selfEvaluation
      }
    }
  }
  general {
    testOrder
    admissionTest {
      name
    }
  }
  scoring {
    scoreMax
    scoringMethod {
      scoringMethod
    }
    selfEvaluation {
      selfEvaluation
    }
  }
  analysis {
    hasSharedAnalysis
    sharedAnalysis {
      text
      image
      writtenVideo
      explainerVideo
    }
  }
  selfEvaluation {
    hasSharedSelfEvaluation
    sharedSelfEvaluation {
      ...selfEvaluation
    }
  }
}
    ${SelfEvaluationFragmentDoc}`;
export const UserAssignmentDetailFragmentDoc = `
    fragment userAssignmentDetail on UserAssignmentType {
  id
  timeToSolve
  timeToLearn
  assignment {
    ...assignmentPractice
  }
  topic {
    name
  }
  subject {
    appName
  }
}
    ${AssignmentPracticeFragmentDoc}`;
export const AssignmentExerciseFragmentDoc = `
    fragment assignmentExercise on AssignmentType {
  id
  isActive
  assignment {
    questionsText
    assignmentText
    assignmentImage
    printingAttachment
    questions {
      heading
      placeholder
      options {
        value
        additionalText
      }
      showAbilityScore
      selfEvaluation {
        ...selfEvaluation
      }
      solutionAnalysis {
        text
        image
        writtenVideo
        explainerVideo
      }
    }
    isAnswerCaseSensitive
    assignmentType {
      type
    }
  }
  scoring {
    canTeacherEvaluate
    scoreMax
    timeToSolve
    scoringMethod {
      scoringMethod
    }
    selfEvaluation {
      selfEvaluation
    }
  }
  analysis {
    hasSharedAnalysis
    sharedAnalysis {
      text
      image
      writtenVideo
      explainerVideo
    }
  }
  selfEvaluation {
    hasSharedSelfEvaluation
    sharedSelfEvaluation {
      ...selfEvaluation
    }
  }
}
    ${SelfEvaluationFragmentDoc}`;
export const UserAssignmentForExerciseFragmentDoc = `
    fragment userAssignmentForExercise on UserAssignmentType {
  id
  scoreMax
  timeToSolve
  timeToLearn
  assignment {
    ...assignmentExercise
  }
  topic {
    name
  }
  subject {
    appName
  }
  envelope
}
    ${AssignmentExerciseFragmentDoc}`;
export const AssignmentPracticeMinimumFragmentDoc = `
    fragment assignmentPracticeMinimum on AssignmentType {
  isActive
  assignment {
    printingAttachment
  }
}
    `;
export const UserAssignmentForExerciseMinimumFragmentDoc = `
    fragment userAssignmentForExerciseMinimum on UserAssignmentType {
  id
  assignment {
    ...assignmentPracticeMinimum
  }
  subject {
    appName
  }
}
    ${AssignmentPracticeMinimumFragmentDoc}`;
export const AssignmentPracticeMinimumMhdFragmentDoc = `
    fragment assignmentPracticeMinimumMhd on AssignmentType {
  isActive
}
    `;
export const UserAssignmentForExerciseMinimumMhdFragmentDoc = `
    fragment userAssignmentForExerciseMinimumMhd on UserAssignmentType {
  id
  assignment {
    ...assignmentPracticeMinimumMhd
  }
  subject {
    appName
  }
}
    ${AssignmentPracticeMinimumMhdFragmentDoc}`;
export const UserAssignmentPracticeFragmentDoc = `
    fragment userAssignmentPractice on UserAssignmentType {
  id
  timeToSolve
  timeToLearn
  assignment {
    ...assignmentPracticeMinimum
  }
}
    ${AssignmentPracticeMinimumFragmentDoc}`;
export const WorstTopicsFragmentDoc = `
    fragment worstTopics on UserTopicType {
  topic {
    name
    id
  }
  abilityScoreAll
}
    `;
export const CheckAnswersDocument = `
    mutation checkAnswers($assignmentId: String!, $timeToSolve: Int!, $userAnswers: [QuestionAutoInput!], $selfEvaluation: Int) {
  answer(
    input: {userAssignmentId: $assignmentId, timeSolveReal: $timeToSolve, autoEval: $userAnswers, selfEval: $selfEvaluation}
  ) {
    scoreReal
  }
}
    `;
export const DeleteBookmarkDocument = `
    mutation deleteBookmark($bookmarkId: String!) {
  deleteBookmark(id: $bookmarkId) {
    objectsDeleted
  }
}
    `;
export const RemoveTopicFromBlackEnvelopeDocument = `
    mutation removeTopicFromBlackEnvelope($id: String!) {
  userTopicUpdate(id: $id, input: {isBlindSpot: false}) {
    isBlindSpot
  }
}
    `;
export const ResetOnboardingDocument = `
    mutation resetOnboarding {
  updatePhone(input: {phoneNumber: "", smsEnabled: false}) {
    status
  }
  updateOnboarding(input: {introduced: false, finished: false, welcome: false}) {
    introduced
    finished
  }
}
    `;
export const SaveBookmarkDocument = `
    mutation saveBookmark($assignmentId: String!) {
  setBookmark(input: {userAssignmentId: $assignmentId}) {
    id
  }
}
    `;
export const SendPhoneValidationCodeDocument = `
    mutation sendPhoneValidationCode($code: String!) {
  validatePhone(input: {validationCode: $code}) {
    status
  }
}
    `;
export const SessionCloseDocument = `
    mutation sessionClose($mood: Int!, $moodText: String!, $assignmentsClose: [AssignmentCloseInput!]!) {
  sessionClose(
    input: {mood: $mood, moodText: $moodText, assignmentsClose: $assignmentsClose}
  ) {
    finished
  }
}
    `;
export const SessionCreateDocument = `
    mutation sessionCreate($sessionLength: Int!) {
  sessionCreate(lengthPreference: $sessionLength) {
    id
    alreadyStarted
    userAssignments {
      assignment {
        assignment {
          printingAttachment
        }
      }
      envelope
      subject {
        appName
      }
    }
  }
}
    `;
export const SessionStartDocument = `
    mutation sessionStart {
  sessionStart {
    id
    lengthActual
    alreadyStarted
    userAssignments {
      ...userAssignmentForExercise
    }
  }
}
    ${UserAssignmentForExerciseFragmentDoc}`;
export const SetTimetableGenericDocument = `
    mutation setTimetableGeneric($from: Date!, $to: Date!, $timeTable: [StudyDayGenericInput!]!) {
  setTimetableGeneric(input: {validFrom: $from, validTo: $to, plan: $timeTable}) {
    plan {
      dayName
      startAt
      duration
    }
  }
}
    `;
export const SetOnboardingFinishedDocument = `
    mutation setOnboardingFinished {
  updateOnboarding(input: {finished: true}) {
    finished
  }
}
    `;
export const SetOnboardingIntroducedDocument = `
    mutation setOnboardingIntroduced {
  updateOnboarding(input: {introduced: true}) {
    introduced
  }
}
    `;
export const SetTimetableDocument = `
    mutation setTimetable($timeTable: [StudyDayInput!]!) {
  setTimetable(input: {plan: $timeTable}) {
    dateStart
    dateEnd
    plan {
      dayIndex
      startAt
      duration
    }
  }
}
    `;
export const SetWelcomedDocument = `
    mutation setWelcomed {
  updateOnboarding(input: {welcome: true}) {
    welcome
  }
}
    `;
export const SolutionUploadConfirmDocument = `
    mutation solutionUploadConfirm($solutionUploadId: String!) {
  solutionUploadConfirm(input: {solutionUploadId: $solutionUploadId})
}
    `;
export const SolutionUploadUrlDocument = `
    mutation solutionUploadUrl($userAssignmentId: String!, $contentType: String!, $fileExtension: String!, $fileSize: Int!) {
  solutionUploadUrl(
    input: {contentType: $contentType, fileExtension: $fileExtension, fileSize: $fileSize, userAssignmentId: $userAssignmentId}
  ) {
    command
    uploadId
    url
  }
}
    `;
export const UpdateParentRewardsDocument = `
    mutation updateParentRewards($rewards: ParentWatchInput!) {
  parentWatchUpdate(inputRewards: $rewards) {
    message
    rewards {
      type
      targetScore
      month
      text
    }
  }
}
    `;
export const UpdatePhoneNumberDocument = `
    mutation updatePhoneNumber($phoneNumber: String!, $smsEnabled: Boolean!) {
  updatePhone(input: {phoneNumber: $phoneNumber, smsEnabled: $smsEnabled}) {
    status
    datetimeLast
  }
}
    `;
export const UpdateSoundDocument = `
    mutation updateSound($withSound: Boolean!) {
  updateUser(input: {withSound: $withSound}) {
    withSound
  }
}
    `;
export const UpdateUserPreferencesDocument = `
    mutation updateUserPreferences($phoneNumber: String!, $preferredSubject: String!, $smsEnabled: Boolean!) {
  updateUser(input: {preferredSubject: $preferredSubject}) {
    preferredSubject
  }
  updatePhone(input: {phoneNumber: $phoneNumber, smsEnabled: $smsEnabled}) {
    status
    datetimeLast
  }
}
    `;
export const UserLoginDocument = `
    mutation userLogin($token: String!) {
  login(loginInput: {handshake: $token}) {
    status
    accessToken
  }
}
    `;
export const ValidateAnswerDocument = `
    mutation validateAnswer($userAssignmentId: String!, $questions: [Boolean!]!) {
  claimEvaluation(
    claimInput: {userAssignmentId: $userAssignmentId, questions: $questions}
  ) {
    scoreReal
  }
}
    `;
export const CheckAchievementsDocument = `
    query checkAchievements {
  checkAchievements(check: true) {
    ...checkAchievements
  }
}
    ${CheckAchievementsFragmentDoc}`;
export const GetAllBookmarksDocument = `
    query getAllBookmarks {
  bookmarks {
    id
    userAssignment {
      id
    }
  }
}
    `;
export const GetAllBookmarksWithSubjectDocument = `
    query getAllBookmarksWithSubject {
  bookmarks {
    id
    userAssignment {
      id
      subject {
        appName
      }
    }
  }
}
    `;
export const GetAllTimetableGenericsDocument = `
    query getAllTimetableGenerics {
  timetableGenericAll {
    created
  }
}
    `;
export const GetAssignmentAnswersDocument = `
    query getAssignmentAnswers($assignmentId: String!) {
  assignments(idList: [$assignmentId]) {
    assignment {
      questions {
        correctAnswers
      }
    }
  }
}
    `;
export const GetAssignmentDetailForCmsDocument = `
    query getAssignmentDetailForCms($assignmentId: String!) {
  assignments(idList: [$assignmentId]) {
    ...assignmentDetail
  }
}
    ${AssignmentDetailFragmentDoc}`;
export const GetAssignmentsEnvelopesDocument = `
    query getAssignmentsEnvelopes($assignments: [String!]) {
  userAssignments(filterInput: {idList: $assignments, limit: 30}) {
    items {
      envelope
      id
    }
  }
}
    `;
export const GetAssignmentsInTopicDocument = `
    query getAssignmentsInTopic($combinedId: String!) {
  userAssignments(filterInput: {combinedTopicId: $combinedId, noPagination: true}) {
    items {
      id
    }
  }
}
    `;
export const GetBookmarkedPracticeAssignmentsDocument = `
    query getBookmarkedPracticeAssignments {
  bookmarks {
    id
    userAssignment {
      ...userAssignmentPractice
    }
  }
}
    ${UserAssignmentPracticeFragmentDoc}`;
export const GetBookmarksAndSubjectsDocument = `
    query getBookmarksAndSubjects {
  bookmarks {
    ...assignmentForBookmark
  }
  subjects {
    appName
  }
}
    ${AssignmentForBookmarkFragmentDoc}`;
export const GetBookmarksCountDocument = `
    query getBookmarksCount {
  bookmarks {
    id
  }
}
    `;
export const GetCombinedTopicAssignmentsDocument = `
    query getCombinedTopicAssignments($combinedTopicId: String!) {
  combinedTopicsCached(idList: [$combinedTopicId]) {
    id
    name
  }
  userAssignmentsCached(
    filterInput: {combinedTopicId: $combinedTopicId, noPagination: true}
  ) {
    items {
      ...topicWithUserAssignments
    }
  }
}
    ${TopicWithUserAssignmentsFragmentDoc}`;
export const GetCombinedTopicTopicsDocument = `
    query getCombinedTopicTopics($combinedTopicId: String!) {
  combinedTopics(idList: [$combinedTopicId]) {
    id
    name
    topics {
      id
      name
    }
  }
  userTopics {
    scoreAggregatePercent
    topic {
      id
    }
    assignmentsGreen
    assignmentsRed
    assignmentsYellow
  }
  userAssignments(filterInput: {combinedTopicId: $combinedTopicId}) {
    totalCount
  }
}
    `;
export const GetCombinedTopicPracticeAssignmentsDocument = `
    query getCombinedTopicPracticeAssignments($combinedTopicId: String!) {
  userAssignments(
    filterInput: {combinedTopicId: $combinedTopicId, noPagination: true}
  ) {
    items {
      ...userAssignmentPractice
    }
  }
  combinedTopics(idList: [$combinedTopicId]) {
    name
  }
}
    ${UserAssignmentPracticeFragmentDoc}`;
export const GetAssignmentsInEnvelopeDocument = `
    query getAssignmentsInEnvelope($combinedId: String!, $envelopeId: String!) {
  userAssignments(
    filterInput: {combinedTopicId: $combinedId, envelope: $envelopeId, noPagination: true}
  ) {
    items {
      id
    }
  }
}
    `;
export const GetCurrentGenericTimetableDocument = `
    query getCurrentGenericTimetable {
  timetableGenericCurrent {
    created
  }
}
    `;
export const GetCurrentSessionCountDocument = `
    query getCurrentSessionCount {
  currentUser {
    sessionsAchieved
  }
}
    `;
export const GetCurrentUserDocument = `
    query getCurrentUser {
  currentUser {
    admissionsDeadline
    email
    name
    vocative
    grade
    sex
    testsToUnlock
    testsToBuy
    preferredSubject
    withSound
    phoneNumber
    phoneValidationDatetimeLast
    phoneValidationPhoneNumber
    preferredSchools {
      name
      pointsForAcceptance
    }
    onboarding {
      introduced
      finished
      welcome
    }
    firstLogin
    sessionsAchieved
    phoneValidationStatus
    smsEnabled
    elixirOfLifeCount
    daysFulfillsPlan
    idExternalZona
  }
  timetableGenericAll {
    created
  }
}
    `;
export const GetDashboardDataDocument = `
    query getDashboardData($timetableFrom: Date!, $timetableTo: Date!) {
  displayOutages {
    ...outage
  }
  timetable(dateFrom: $timetableFrom, dateTo: $timetableTo) {
    timetable {
      dateStart
      dateEnd
      plan {
        ...studyDay
      }
    }
  }
  testPresence(refresh: true) {
    dateTime
  }
  envelopesSummary {
    ...envelopeSummary
  }
  blindSpotTopics {
    ...blindSpotTopic
  }
  lastAchievements(count: 4) {
    ...lastAchievement
  }
  checkAchievements(check: true) {
    ...checkAchievements
  }
  userTopicsWorst {
    ...worstTopics
  }
}
    ${OutageFragmentDoc}
${StudyDayFragmentDoc}
${EnvelopeSummaryFragmentDoc}
${BlindSpotTopicFragmentDoc}
${LastAchievementFragmentDoc}
${CheckAchievementsFragmentDoc}
${WorstTopicsFragmentDoc}`;
export const GetEnvelopeCombinedTopicAssignmentsDocument = `
    query getEnvelopeCombinedTopicAssignments($combinedTopicId: String!, $envelopeId: String!) {
  combinedTopics(idList: [$combinedTopicId]) {
    id
    name
  }
  userAssignments(
    filterInput: {envelope: $envelopeId, combinedTopicId: $combinedTopicId, noPagination: true}
  ) {
    items {
      ...topicWithUserAssignments
    }
  }
}
    ${TopicWithUserAssignmentsFragmentDoc}`;
export const GetEnvelopeCombinedTopicTopicsDocument = `
    query getEnvelopeCombinedTopicTopics($combinedTopicId: String!, $envelopeId: String!, $isRed: Boolean!, $isGreen: Boolean!, $isOrange: Boolean!) {
  combinedTopics(idList: [$combinedTopicId]) {
    id
    name
    topics {
      id
      name
    }
  }
  userTopics {
    scoreAggregatePercent
    topic {
      id
    }
  }
  userTopics @include(if: $isGreen) {
    hasAssignmentsGreen @include(if: $isGreen)
  }
  userTopics @include(if: $isRed) {
    hasAssignmentsRed
  }
  userTopics @include(if: $isOrange) {
    hasAssignmentsYellow
  }
  userAssignments(
    filterInput: {envelope: $envelopeId, combinedTopicId: $combinedTopicId}
  ) {
    totalCount
  }
}
    `;
export const GetEnvelopeCombinedTopicPracticeAssignmentsDocument = `
    query getEnvelopeCombinedTopicPracticeAssignments($envelopeId: String!, $combinedTopicId: String!) {
  userAssignments(
    filterInput: {envelope: $envelopeId, combinedTopicId: $combinedTopicId, noPagination: true}
  ) {
    items {
      ...userAssignmentPractice
    }
  }
  combinedTopics(idList: [$combinedTopicId]) {
    name
  }
}
    ${UserAssignmentPracticeFragmentDoc}`;
export const GetEnvelopeSubjectsTopicsDocument = `
    query getEnvelopeSubjectsTopics($isRed: Boolean!, $isGreen: Boolean!, $isOrange: Boolean!) {
  subjects {
    id
    appAbbr
    appName
    isFavorite
    superTopics {
      id
      name
    }
  }
  userSubjects {
    scoreAggregatePercent
    subject {
      id
    }
  }
  userSuperTopics {
    scoreAggregatePercent
    superTopic {
      id
    }
  }
  userSubjects @include(if: $isGreen) {
    assignmentsGreen
  }
  userSubjects @include(if: $isOrange) {
    assignmentsYellow
  }
  userSubjects @include(if: $isRed) {
    assignmentsRed
  }
  userSuperTopics @include(if: $isGreen) {
    assignmentsGreen
  }
  userSuperTopics @include(if: $isOrange) {
    assignmentsYellow
  }
  userSuperTopics @include(if: $isRed) {
    assignmentsRed
  }
  envelopesSummary {
    green
    yellow
    red
  }
}
    `;
export const GetEnvelopeSuperTopicPracticeAssignmentsDocument = `
    query getEnvelopeSuperTopicPracticeAssignments($envelopeId: String!, $superTopicId: String!) {
  userAssignments(
    filterInput: {envelope: $envelopeId, superTopicId: $superTopicId, noPagination: true}
  ) {
    items {
      ...userAssignmentPractice
    }
  }
  superTopics(idList: [$superTopicId]) {
    name
  }
}
    ${UserAssignmentPracticeFragmentDoc}`;
export const GetEnvelopeSuperTopicTopicsDocument = `
    query getEnvelopeSuperTopicTopics($superTopicId: String!, $envelopeId: String!, $isRed: Boolean!, $isGreen: Boolean!, $isOrange: Boolean!) {
  superTopics(idList: [$superTopicId]) {
    id
    name
    combinedTopics {
      id
      name
    }
  }
  userCombinedTopics {
    hasAssignments
    combinedTopic {
      id
    }
  }
  userCombinedTopics @include(if: $isGreen) {
    assignmentsGreen
  }
  userCombinedTopics @include(if: $isRed) {
    assignmentsRed
  }
  userCombinedTopics @include(if: $isOrange) {
    assignmentsYellow
  }
  userAssignments(
    filterInput: {envelope: $envelopeId, superTopicId: $superTopicId}
  ) {
    totalCount
  }
}
    `;
export const GetEnvelopeTopicAssignmentsDocument = `
    query getEnvelopeTopicAssignments($topicId: String!, $envelopeId: String!) {
  userAssignments(filterInput: {topicId: $topicId, envelope: $envelopeId}) {
    items {
      ...topicWithUserAssignments
    }
    totalCount
  }
  topics(idList: [$topicId]) {
    name
  }
}
    ${TopicWithUserAssignmentsFragmentDoc}`;
export const GetEnvelopeTopicPracticeAssignmentsDocument = `
    query getEnvelopeTopicPracticeAssignments($envelopeId: String!, $topicId: String!) {
  userAssignments(filterInput: {envelope: $envelopeId, topicId: $topicId}) {
    items {
      ...userAssignmentPractice
    }
    totalCount
  }
  topics(idList: [$topicId]) {
    name
  }
}
    ${UserAssignmentPracticeFragmentDoc}`;
export const GetFaqDocument = `
    query getFaq {
  faqAll {
    title
    description
  }
}
    `;
export const GetOnboardingAssignmentsDocument = `
    query getOnboardingAssignments {
  userAssignments(filterInput: {isOnboarding: true}) {
    items {
      ...userAssignmentForExercise
    }
  }
}
    ${UserAssignmentForExerciseFragmentDoc}`;
export const GetOnboardingStatusDocument = `
    query getOnboardingStatus {
  onboarding {
    finished
  }
}
    `;
export const GetParentRewardsDocument = `
    query getParentRewards {
  parentWatch {
    message
    rewards {
      type
      targetScore
      month
      text
    }
    user {
      name
      admissionsDeadline
    }
  }
}
    `;
export const GetPracticeEnvelopeDocument = `
    query getPracticeEnvelope($envelopeId: String!) {
  trainingEnvelope(envelope: $envelopeId) {
    ...userAssignmentPractice
  }
}
    ${UserAssignmentPracticeFragmentDoc}`;
export const GetPracticeMhdDocument = `
    query getPracticeMhd($length: Int!) {
  trainingMhd(lengthPreference: $length) {
    userAssignments {
      ...userAssignmentForExerciseMinimumMhd
    }
  }
}
    ${UserAssignmentForExerciseMinimumMhdFragmentDoc}`;
export const GetPracticeMixDocument = `
    query getPracticeMix($length: Int!) {
  training(lengthPreference: $length) {
    userAssignments {
      ...userAssignmentForExerciseMinimum
    }
  }
}
    ${UserAssignmentForExerciseMinimumFragmentDoc}`;
export const GetSessionResultsDocument = `
    query getSessionResults($dateFrom: Date!) {
  userSessionReview(dateFrom: $dateFrom) {
    dateTested
    scoreMaxTotal
    scoreRealTotal
  }
}
    `;
export const GetStatisticsDataDocument = `
    query getStatisticsData {
  checkAchievements {
    achievements {
      ...badge
    }
  }
  parentWatch {
    message
    rewards {
      targetScore
      month
      text
    }
  }
}
    ${BadgeFragmentDoc}`;
export const GetStreakDocument = `
    query getStreak($streakFrom: Date!, $streakTo: Date!) {
  timetable(dateFrom: $streakFrom, dateTo: $streakTo) {
    daysFulfillsPlan
  }
}
    `;
export const GetSubjectsDocument = `
    query getSubjects {
  subjects {
    appAbbr
    appName
    isFavorite
  }
}
    `;
export const GetSubjectsTopicsDocument = `
    query getSubjectsTopics {
  subjectsCached {
    id
    appAbbr
    appName
    isFavorite
    superTopics {
      id
      name
    }
  }
  bookmarksCached {
    id
  }
  userSubjectsCached {
    scoreAggregatePercent
    subject {
      id
    }
  }
  userSuperTopicsCached {
    scoreAggregatePercent
    superTopic {
      id
    }
    hasAssignments
  }
}
    `;
export const GetSuperTopicPracticeAssignmentsDocument = `
    query getSuperTopicPracticeAssignments($superTopicId: String!) {
  userAssignments(filterInput: {superTopicId: $superTopicId, noPagination: true}) {
    items {
      ...userAssignmentPractice
    }
  }
  superTopics(idList: [$superTopicId]) {
    name
  }
}
    ${UserAssignmentPracticeFragmentDoc}`;
export const GetSuperTopicTopicsDocument = `
    query getSuperTopicTopics($superTopicId: String!) {
  superTopicsCached(idList: [$superTopicId]) {
    id
    name
    combinedTopics {
      id
      name
    }
  }
  userCombinedTopicsCached {
    scoreAggregatePercent
    combinedTopic {
      id
    }
    hasAssignments
    assignmentsRed
    assignmentsYellow
    assignmentsGreen
  }
  userAssignmentsCached(filterInput: {superTopicId: $superTopicId}) {
    totalCount
  }
}
    `;
export const GetTestPresencesDocument = `
    query getTestPresences {
  testPresence {
    dateTime
    scoreMath
    scoreCzech
    absolved
    form
  }
}
    `;
export const GetTimetableDocument = `
    query getTimetable($dateFrom: Date!, $dateTo: Date!) {
  timetable(dateFrom: $dateFrom, dateTo: $dateTo) {
    timetable {
      dateStart
      dateEnd
      plan {
        ...studyDay
      }
    }
  }
  testPresence(refresh: true) {
    dateTime
  }
}
    ${StudyDayFragmentDoc}`;
export const GetTimetableGenericDocument = `
    query getTimetableGeneric {
  timetableGenericCurrent {
    plan {
      dayName
      startAt
      duration
    }
  }
}
    `;
export const GetTopicAssignmentsDocument = `
    query getTopicAssignments($topicId: String!) {
  userAssignments(filterInput: {topicId: $topicId}) {
    items {
      ...topicWithUserAssignments
    }
    totalCount
  }
  topics(idList: [$topicId]) {
    name
  }
}
    ${TopicWithUserAssignmentsFragmentDoc}`;
export const GetTopicAssignmentsListDocument = `
    query getTopicAssignmentsList($topicId: String!) {
  userAssignments(filterInput: {topicId: $topicId, noPagination: true}) {
    items {
      ...userAssignmentPractice
    }
  }
  topics(idList: [$topicId]) {
    name
  }
}
    ${UserAssignmentPracticeFragmentDoc}`;
export const GetTopicPracticeAssignmentsDocument = `
    query getTopicPracticeAssignments($topicId: String!) {
  userAssignments(filterInput: {topicId: $topicId, noPagination: true}) {
    items {
      ...userAssignmentPractice
    }
  }
  topics(idList: [$topicId]) {
    name
  }
}
    ${UserAssignmentPracticeFragmentDoc}`;
export const GetUserAssignmentDocument = `
    query getUserAssignment($assignmentId: String!) {
  userAssignments(filterInput: {idList: [$assignmentId]}) {
    items {
      ...userAssignmentDetail
    }
  }
}
    ${UserAssignmentDetailFragmentDoc}`;
export const GetUserAssignmentPaginatedDocument = `
    query getUserAssignmentPaginated($assignmentIds: [String!]!) {
  userAssignments(filterInput: {idList: $assignmentIds, limit: 30}) {
    items {
      ...userAssignmentDetail
    }
  }
}
    ${UserAssignmentDetailFragmentDoc}`;
export const SearchAssignmentsDocument = `
    query searchAssignments($search: String!) {
  userAssignmentsFulltext(fulltextInput: {fulltext: $search}) {
    items {
      id
      ...topicWithUserAssignments
    }
    totalCount
  }
}
    ${TopicWithUserAssignmentsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    checkAnswers: build.mutation<CheckAnswersMutation, CheckAnswersMutationVariables>({
      query: variables => ({ document: CheckAnswersDocument, variables }),
    }),
    deleteBookmark: build.mutation<DeleteBookmarkMutation, DeleteBookmarkMutationVariables>({
      query: variables => ({ document: DeleteBookmarkDocument, variables }),
    }),
    removeTopicFromBlackEnvelope: build.mutation<
      RemoveTopicFromBlackEnvelopeMutation,
      RemoveTopicFromBlackEnvelopeMutationVariables
    >({
      query: variables => ({ document: RemoveTopicFromBlackEnvelopeDocument, variables }),
    }),
    resetOnboarding: build.mutation<ResetOnboardingMutation, ResetOnboardingMutationVariables | void>({
      query: variables => ({ document: ResetOnboardingDocument, variables }),
    }),
    saveBookmark: build.mutation<SaveBookmarkMutation, SaveBookmarkMutationVariables>({
      query: variables => ({ document: SaveBookmarkDocument, variables }),
    }),
    sendPhoneValidationCode: build.mutation<SendPhoneValidationCodeMutation, SendPhoneValidationCodeMutationVariables>({
      query: variables => ({ document: SendPhoneValidationCodeDocument, variables }),
    }),
    sessionClose: build.mutation<SessionCloseMutation, SessionCloseMutationVariables>({
      query: variables => ({ document: SessionCloseDocument, variables }),
    }),
    sessionCreate: build.mutation<SessionCreateMutation, SessionCreateMutationVariables>({
      query: variables => ({ document: SessionCreateDocument, variables }),
    }),
    sessionStart: build.mutation<SessionStartMutation, SessionStartMutationVariables | void>({
      query: variables => ({ document: SessionStartDocument, variables }),
    }),
    setTimetableGeneric: build.mutation<SetTimetableGenericMutation, SetTimetableGenericMutationVariables>({
      query: variables => ({ document: SetTimetableGenericDocument, variables }),
    }),
    setOnboardingFinished: build.mutation<SetOnboardingFinishedMutation, SetOnboardingFinishedMutationVariables | void>(
      {
        query: variables => ({ document: SetOnboardingFinishedDocument, variables }),
      },
    ),
    setOnboardingIntroduced: build.mutation<
      SetOnboardingIntroducedMutation,
      SetOnboardingIntroducedMutationVariables | void
    >({
      query: variables => ({ document: SetOnboardingIntroducedDocument, variables }),
    }),
    setTimetable: build.mutation<SetTimetableMutation, SetTimetableMutationVariables>({
      query: variables => ({ document: SetTimetableDocument, variables }),
    }),
    setWelcomed: build.mutation<SetWelcomedMutation, SetWelcomedMutationVariables | void>({
      query: variables => ({ document: SetWelcomedDocument, variables }),
    }),
    solutionUploadConfirm: build.mutation<SolutionUploadConfirmMutation, SolutionUploadConfirmMutationVariables>({
      query: variables => ({ document: SolutionUploadConfirmDocument, variables }),
    }),
    solutionUploadUrl: build.mutation<SolutionUploadUrlMutation, SolutionUploadUrlMutationVariables>({
      query: variables => ({ document: SolutionUploadUrlDocument, variables }),
    }),
    updateParentRewards: build.mutation<UpdateParentRewardsMutation, UpdateParentRewardsMutationVariables>({
      query: variables => ({ document: UpdateParentRewardsDocument, variables }),
    }),
    updatePhoneNumber: build.mutation<UpdatePhoneNumberMutation, UpdatePhoneNumberMutationVariables>({
      query: variables => ({ document: UpdatePhoneNumberDocument, variables }),
    }),
    updateSound: build.mutation<UpdateSoundMutation, UpdateSoundMutationVariables>({
      query: variables => ({ document: UpdateSoundDocument, variables }),
    }),
    updateUserPreferences: build.mutation<UpdateUserPreferencesMutation, UpdateUserPreferencesMutationVariables>({
      query: variables => ({ document: UpdateUserPreferencesDocument, variables }),
    }),
    userLogin: build.mutation<UserLoginMutation, UserLoginMutationVariables>({
      query: variables => ({ document: UserLoginDocument, variables }),
    }),
    validateAnswer: build.mutation<ValidateAnswerMutation, ValidateAnswerMutationVariables>({
      query: variables => ({ document: ValidateAnswerDocument, variables }),
    }),
    checkAchievements: build.query<CheckAchievementsQuery, CheckAchievementsQueryVariables | void>({
      query: variables => ({ document: CheckAchievementsDocument, variables }),
    }),
    getAllBookmarks: build.query<GetAllBookmarksQuery, GetAllBookmarksQueryVariables | void>({
      query: variables => ({ document: GetAllBookmarksDocument, variables }),
    }),
    getAllBookmarksWithSubject: build.query<
      GetAllBookmarksWithSubjectQuery,
      GetAllBookmarksWithSubjectQueryVariables | void
    >({
      query: variables => ({ document: GetAllBookmarksWithSubjectDocument, variables }),
    }),
    getAllTimetableGenerics: build.query<GetAllTimetableGenericsQuery, GetAllTimetableGenericsQueryVariables | void>({
      query: variables => ({ document: GetAllTimetableGenericsDocument, variables }),
    }),
    getAssignmentAnswers: build.query<GetAssignmentAnswersQuery, GetAssignmentAnswersQueryVariables>({
      query: variables => ({ document: GetAssignmentAnswersDocument, variables }),
    }),
    getAssignmentDetailForCms: build.query<GetAssignmentDetailForCmsQuery, GetAssignmentDetailForCmsQueryVariables>({
      query: variables => ({ document: GetAssignmentDetailForCmsDocument, variables }),
    }),
    getAssignmentsEnvelopes: build.query<GetAssignmentsEnvelopesQuery, GetAssignmentsEnvelopesQueryVariables | void>({
      query: variables => ({ document: GetAssignmentsEnvelopesDocument, variables }),
    }),
    getAssignmentsInTopic: build.query<GetAssignmentsInTopicQuery, GetAssignmentsInTopicQueryVariables>({
      query: variables => ({ document: GetAssignmentsInTopicDocument, variables }),
    }),
    getBookmarkedPracticeAssignments: build.query<
      GetBookmarkedPracticeAssignmentsQuery,
      GetBookmarkedPracticeAssignmentsQueryVariables | void
    >({
      query: variables => ({ document: GetBookmarkedPracticeAssignmentsDocument, variables }),
    }),
    getBookmarksAndSubjects: build.query<GetBookmarksAndSubjectsQuery, GetBookmarksAndSubjectsQueryVariables | void>({
      query: variables => ({ document: GetBookmarksAndSubjectsDocument, variables }),
    }),
    getBookmarksCount: build.query<GetBookmarksCountQuery, GetBookmarksCountQueryVariables | void>({
      query: variables => ({ document: GetBookmarksCountDocument, variables }),
    }),
    getCombinedTopicAssignments: build.query<
      GetCombinedTopicAssignmentsQuery,
      GetCombinedTopicAssignmentsQueryVariables
    >({
      query: variables => ({ document: GetCombinedTopicAssignmentsDocument, variables }),
    }),
    getCombinedTopicTopics: build.query<GetCombinedTopicTopicsQuery, GetCombinedTopicTopicsQueryVariables>({
      query: variables => ({ document: GetCombinedTopicTopicsDocument, variables }),
    }),
    getCombinedTopicPracticeAssignments: build.query<
      GetCombinedTopicPracticeAssignmentsQuery,
      GetCombinedTopicPracticeAssignmentsQueryVariables
    >({
      query: variables => ({ document: GetCombinedTopicPracticeAssignmentsDocument, variables }),
    }),
    getAssignmentsInEnvelope: build.query<GetAssignmentsInEnvelopeQuery, GetAssignmentsInEnvelopeQueryVariables>({
      query: variables => ({ document: GetAssignmentsInEnvelopeDocument, variables }),
    }),
    getCurrentGenericTimetable: build.query<
      GetCurrentGenericTimetableQuery,
      GetCurrentGenericTimetableQueryVariables | void
    >({
      query: variables => ({ document: GetCurrentGenericTimetableDocument, variables }),
    }),
    getCurrentSessionCount: build.query<GetCurrentSessionCountQuery, GetCurrentSessionCountQueryVariables | void>({
      query: variables => ({ document: GetCurrentSessionCountDocument, variables }),
    }),
    getCurrentUser: build.query<GetCurrentUserQuery, GetCurrentUserQueryVariables | void>({
      query: variables => ({ document: GetCurrentUserDocument, variables }),
    }),
    getDashboardData: build.query<GetDashboardDataQuery, GetDashboardDataQueryVariables>({
      query: variables => ({ document: GetDashboardDataDocument, variables }),
    }),
    getEnvelopeCombinedTopicAssignments: build.query<
      GetEnvelopeCombinedTopicAssignmentsQuery,
      GetEnvelopeCombinedTopicAssignmentsQueryVariables
    >({
      query: variables => ({ document: GetEnvelopeCombinedTopicAssignmentsDocument, variables }),
    }),
    getEnvelopeCombinedTopicTopics: build.query<
      GetEnvelopeCombinedTopicTopicsQuery,
      GetEnvelopeCombinedTopicTopicsQueryVariables
    >({
      query: variables => ({ document: GetEnvelopeCombinedTopicTopicsDocument, variables }),
    }),
    getEnvelopeCombinedTopicPracticeAssignments: build.query<
      GetEnvelopeCombinedTopicPracticeAssignmentsQuery,
      GetEnvelopeCombinedTopicPracticeAssignmentsQueryVariables
    >({
      query: variables => ({ document: GetEnvelopeCombinedTopicPracticeAssignmentsDocument, variables }),
    }),
    getEnvelopeSubjectsTopics: build.query<GetEnvelopeSubjectsTopicsQuery, GetEnvelopeSubjectsTopicsQueryVariables>({
      query: variables => ({ document: GetEnvelopeSubjectsTopicsDocument, variables }),
    }),
    getEnvelopeSuperTopicPracticeAssignments: build.query<
      GetEnvelopeSuperTopicPracticeAssignmentsQuery,
      GetEnvelopeSuperTopicPracticeAssignmentsQueryVariables
    >({
      query: variables => ({ document: GetEnvelopeSuperTopicPracticeAssignmentsDocument, variables }),
    }),
    getEnvelopeSuperTopicTopics: build.query<
      GetEnvelopeSuperTopicTopicsQuery,
      GetEnvelopeSuperTopicTopicsQueryVariables
    >({
      query: variables => ({ document: GetEnvelopeSuperTopicTopicsDocument, variables }),
    }),
    getEnvelopeTopicAssignments: build.query<
      GetEnvelopeTopicAssignmentsQuery,
      GetEnvelopeTopicAssignmentsQueryVariables
    >({
      query: variables => ({ document: GetEnvelopeTopicAssignmentsDocument, variables }),
    }),
    getEnvelopeTopicPracticeAssignments: build.query<
      GetEnvelopeTopicPracticeAssignmentsQuery,
      GetEnvelopeTopicPracticeAssignmentsQueryVariables
    >({
      query: variables => ({ document: GetEnvelopeTopicPracticeAssignmentsDocument, variables }),
    }),
    getFaq: build.query<GetFaqQuery, GetFaqQueryVariables | void>({
      query: variables => ({ document: GetFaqDocument, variables }),
    }),
    getOnboardingAssignments: build.query<GetOnboardingAssignmentsQuery, GetOnboardingAssignmentsQueryVariables | void>(
      {
        query: variables => ({ document: GetOnboardingAssignmentsDocument, variables }),
      },
    ),
    getOnboardingStatus: build.query<GetOnboardingStatusQuery, GetOnboardingStatusQueryVariables | void>({
      query: variables => ({ document: GetOnboardingStatusDocument, variables }),
    }),
    getParentRewards: build.query<GetParentRewardsQuery, GetParentRewardsQueryVariables | void>({
      query: variables => ({ document: GetParentRewardsDocument, variables }),
    }),
    getPracticeEnvelope: build.query<GetPracticeEnvelopeQuery, GetPracticeEnvelopeQueryVariables>({
      query: variables => ({ document: GetPracticeEnvelopeDocument, variables }),
    }),
    getPracticeMhd: build.query<GetPracticeMhdQuery, GetPracticeMhdQueryVariables>({
      query: variables => ({ document: GetPracticeMhdDocument, variables }),
    }),
    getPracticeMix: build.query<GetPracticeMixQuery, GetPracticeMixQueryVariables>({
      query: variables => ({ document: GetPracticeMixDocument, variables }),
    }),
    getSessionResults: build.query<GetSessionResultsQuery, GetSessionResultsQueryVariables>({
      query: variables => ({ document: GetSessionResultsDocument, variables }),
    }),
    getStatisticsData: build.query<GetStatisticsDataQuery, GetStatisticsDataQueryVariables | void>({
      query: variables => ({ document: GetStatisticsDataDocument, variables }),
    }),
    getStreak: build.query<GetStreakQuery, GetStreakQueryVariables>({
      query: variables => ({ document: GetStreakDocument, variables }),
    }),
    getSubjects: build.query<GetSubjectsQuery, GetSubjectsQueryVariables | void>({
      query: variables => ({ document: GetSubjectsDocument, variables }),
    }),
    getSubjectsTopics: build.query<GetSubjectsTopicsQuery, GetSubjectsTopicsQueryVariables | void>({
      query: variables => ({ document: GetSubjectsTopicsDocument, variables }),
    }),
    getSuperTopicPracticeAssignments: build.query<
      GetSuperTopicPracticeAssignmentsQuery,
      GetSuperTopicPracticeAssignmentsQueryVariables
    >({
      query: variables => ({ document: GetSuperTopicPracticeAssignmentsDocument, variables }),
    }),
    getSuperTopicTopics: build.query<GetSuperTopicTopicsQuery, GetSuperTopicTopicsQueryVariables>({
      query: variables => ({ document: GetSuperTopicTopicsDocument, variables }),
    }),
    getTestPresences: build.query<GetTestPresencesQuery, GetTestPresencesQueryVariables | void>({
      query: variables => ({ document: GetTestPresencesDocument, variables }),
    }),
    getTimetable: build.query<GetTimetableQuery, GetTimetableQueryVariables>({
      query: variables => ({ document: GetTimetableDocument, variables }),
    }),
    getTimetableGeneric: build.query<GetTimetableGenericQuery, GetTimetableGenericQueryVariables | void>({
      query: variables => ({ document: GetTimetableGenericDocument, variables }),
    }),
    getTopicAssignments: build.query<GetTopicAssignmentsQuery, GetTopicAssignmentsQueryVariables>({
      query: variables => ({ document: GetTopicAssignmentsDocument, variables }),
    }),
    getTopicAssignmentsList: build.query<GetTopicAssignmentsListQuery, GetTopicAssignmentsListQueryVariables>({
      query: variables => ({ document: GetTopicAssignmentsListDocument, variables }),
    }),
    getTopicPracticeAssignments: build.query<
      GetTopicPracticeAssignmentsQuery,
      GetTopicPracticeAssignmentsQueryVariables
    >({
      query: variables => ({ document: GetTopicPracticeAssignmentsDocument, variables }),
    }),
    getUserAssignment: build.query<GetUserAssignmentQuery, GetUserAssignmentQueryVariables>({
      query: variables => ({ document: GetUserAssignmentDocument, variables }),
    }),
    getUserAssignmentPaginated: build.query<GetUserAssignmentPaginatedQuery, GetUserAssignmentPaginatedQueryVariables>({
      query: variables => ({ document: GetUserAssignmentPaginatedDocument, variables }),
    }),
    searchAssignments: build.query<SearchAssignmentsQuery, SearchAssignmentsQueryVariables>({
      query: variables => ({ document: SearchAssignmentsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useCheckAnswersMutation,
  useDeleteBookmarkMutation,
  useRemoveTopicFromBlackEnvelopeMutation,
  useResetOnboardingMutation,
  useSaveBookmarkMutation,
  useSendPhoneValidationCodeMutation,
  useSessionCloseMutation,
  useSessionCreateMutation,
  useSessionStartMutation,
  useSetTimetableGenericMutation,
  useSetOnboardingFinishedMutation,
  useSetOnboardingIntroducedMutation,
  useSetTimetableMutation,
  useSetWelcomedMutation,
  useSolutionUploadConfirmMutation,
  useSolutionUploadUrlMutation,
  useUpdateParentRewardsMutation,
  useUpdatePhoneNumberMutation,
  useUpdateSoundMutation,
  useUpdateUserPreferencesMutation,
  useUserLoginMutation,
  useValidateAnswerMutation,
  useCheckAchievementsQuery,
  useLazyCheckAchievementsQuery,
  useGetAllBookmarksQuery,
  useLazyGetAllBookmarksQuery,
  useGetAllBookmarksWithSubjectQuery,
  useLazyGetAllBookmarksWithSubjectQuery,
  useGetAllTimetableGenericsQuery,
  useLazyGetAllTimetableGenericsQuery,
  useGetAssignmentAnswersQuery,
  useLazyGetAssignmentAnswersQuery,
  useGetAssignmentDetailForCmsQuery,
  useLazyGetAssignmentDetailForCmsQuery,
  useGetAssignmentsEnvelopesQuery,
  useLazyGetAssignmentsEnvelopesQuery,
  useGetAssignmentsInTopicQuery,
  useLazyGetAssignmentsInTopicQuery,
  useGetBookmarkedPracticeAssignmentsQuery,
  useLazyGetBookmarkedPracticeAssignmentsQuery,
  useGetBookmarksAndSubjectsQuery,
  useLazyGetBookmarksAndSubjectsQuery,
  useGetBookmarksCountQuery,
  useLazyGetBookmarksCountQuery,
  useGetCombinedTopicAssignmentsQuery,
  useLazyGetCombinedTopicAssignmentsQuery,
  useGetCombinedTopicTopicsQuery,
  useLazyGetCombinedTopicTopicsQuery,
  useGetCombinedTopicPracticeAssignmentsQuery,
  useLazyGetCombinedTopicPracticeAssignmentsQuery,
  useGetAssignmentsInEnvelopeQuery,
  useLazyGetAssignmentsInEnvelopeQuery,
  useGetCurrentGenericTimetableQuery,
  useLazyGetCurrentGenericTimetableQuery,
  useGetCurrentSessionCountQuery,
  useLazyGetCurrentSessionCountQuery,
  useGetCurrentUserQuery,
  useLazyGetCurrentUserQuery,
  useGetDashboardDataQuery,
  useLazyGetDashboardDataQuery,
  useGetEnvelopeCombinedTopicAssignmentsQuery,
  useLazyGetEnvelopeCombinedTopicAssignmentsQuery,
  useGetEnvelopeCombinedTopicTopicsQuery,
  useLazyGetEnvelopeCombinedTopicTopicsQuery,
  useGetEnvelopeCombinedTopicPracticeAssignmentsQuery,
  useLazyGetEnvelopeCombinedTopicPracticeAssignmentsQuery,
  useGetEnvelopeSubjectsTopicsQuery,
  useLazyGetEnvelopeSubjectsTopicsQuery,
  useGetEnvelopeSuperTopicPracticeAssignmentsQuery,
  useLazyGetEnvelopeSuperTopicPracticeAssignmentsQuery,
  useGetEnvelopeSuperTopicTopicsQuery,
  useLazyGetEnvelopeSuperTopicTopicsQuery,
  useGetEnvelopeTopicAssignmentsQuery,
  useLazyGetEnvelopeTopicAssignmentsQuery,
  useGetEnvelopeTopicPracticeAssignmentsQuery,
  useLazyGetEnvelopeTopicPracticeAssignmentsQuery,
  useGetFaqQuery,
  useLazyGetFaqQuery,
  useGetOnboardingAssignmentsQuery,
  useLazyGetOnboardingAssignmentsQuery,
  useGetOnboardingStatusQuery,
  useLazyGetOnboardingStatusQuery,
  useGetParentRewardsQuery,
  useLazyGetParentRewardsQuery,
  useGetPracticeEnvelopeQuery,
  useLazyGetPracticeEnvelopeQuery,
  useGetPracticeMhdQuery,
  useLazyGetPracticeMhdQuery,
  useGetPracticeMixQuery,
  useLazyGetPracticeMixQuery,
  useGetSessionResultsQuery,
  useLazyGetSessionResultsQuery,
  useGetStatisticsDataQuery,
  useLazyGetStatisticsDataQuery,
  useGetStreakQuery,
  useLazyGetStreakQuery,
  useGetSubjectsQuery,
  useLazyGetSubjectsQuery,
  useGetSubjectsTopicsQuery,
  useLazyGetSubjectsTopicsQuery,
  useGetSuperTopicPracticeAssignmentsQuery,
  useLazyGetSuperTopicPracticeAssignmentsQuery,
  useGetSuperTopicTopicsQuery,
  useLazyGetSuperTopicTopicsQuery,
  useGetTestPresencesQuery,
  useLazyGetTestPresencesQuery,
  useGetTimetableQuery,
  useLazyGetTimetableQuery,
  useGetTimetableGenericQuery,
  useLazyGetTimetableGenericQuery,
  useGetTopicAssignmentsQuery,
  useLazyGetTopicAssignmentsQuery,
  useGetTopicAssignmentsListQuery,
  useLazyGetTopicAssignmentsListQuery,
  useGetTopicPracticeAssignmentsQuery,
  useLazyGetTopicPracticeAssignmentsQuery,
  useGetUserAssignmentQuery,
  useLazyGetUserAssignmentQuery,
  useGetUserAssignmentPaginatedQuery,
  useLazyGetUserAssignmentPaginatedQuery,
  useSearchAssignmentsQuery,
  useLazySearchAssignmentsQuery,
} = injectedRtkApi;
