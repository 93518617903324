import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import { useEffect, useState } from "react";
import type { FC } from "react";

import Layout from "./_Layout";

import { AbilityScore, AnswerExercise, ViceMoznostiDoleOption, ErrorModal } from "components";
import { ExerciseSelfEvaluation } from "features";

import { useLazyGetAssignmentAnswersQuery, useCheckAnswersMutation } from "api/generated";
import type { UserAssignmentForExerciseFragment } from "api/generated";

import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  setAnswer,
  setAssignmentEvaluation,
  setTimeLearn,
  getAssignmentResult,
  setAbilityScore,
  setTips,
} from "store/slices/exercise";
import {
  getAssignmentTime,
  startAssignmentTimer,
  resetAssignmentTimer,
  stopAssignmentTimer,
  setIsTimerShown,
} from "store/slices/timer";

import { evaluateGenericType, getSolutionVariant } from "utils/getSolutionVariant";
import { useSaveExerciseSelfEvaluation } from "utils/hooks/useSaveExerciseSelfEvaluation";
import { getTimeToLearn } from "utils/getTimeToLearn";
import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";
import { isSelfEvaluationForEveryAssignment } from "utils/valueTranslators";
import { processAnswerResponse } from "utils/processAnswerResponse";
import { isAbilityScoreShown } from "utils/isAbilityScoreShown";
import { changeDistinctAbilityScore, getAbilityScoreOptions } from "utils/changeAbilityScore";
import { processApiError, processExerciseError, logGeneralExerciseError } from "utils/processApiError";
import { useEmptyAssignmentAnswer } from "utils/hooks/useEmptyAssignmentAnswer";

/* Vg88XS8doR7IqSBg5PuQ */

interface Props {
  data: UserAssignmentForExerciseFragment;
  userAssignmentId: string;
  evaluateAnswer: boolean;
  onNextClick: () => void;
}

const ViceMoznostiDole: FC<Props> = ({ data, userAssignmentId, evaluateAnswer, onNextClick }) => {
  const questionsCount = data.assignment?.assignment?.questions.length ?? 0;
  const isSelfEvaluation = data.assignment?.scoring?.scoringMethod?.scoringMethod === "sebeopravení";

  const saveExerciseSelfEvaluation = useSaveExerciseSelfEvaluation();
  const dispatch = useAppDispatch();
  const assignmentResults = useAppSelector(getAssignmentResult<string>(userAssignmentId));
  const time = useAppSelector(getAssignmentTime);
  const { imageUrl } = useImageUrlResolver(data.assignment?.assignment?.assignmentImage);
  const { setEmptyExerciseAnswer } = useEmptyAssignmentAnswer();

  const [sendAnswers, { error: apiMutationError, isLoading: isSendingAnswers }] = useCheckAnswersMutation();
  const [trigger, { data: answerData, isLoading: isGettingAnswers, error: apiLazyError }] =
    useLazyGetAssignmentAnswersQuery();

  const [submit, setSubmit] = useState(false);
  const [goNext, setGoNext] = useState(false);
  const [isRequestsLoading, setRequestsLoading] = useState(false);
  const [assignmentPoints, setAssignmentPoints] = useState<{ points: number | null; maxPoints: number | null }>({
    points: null,
    maxPoints: null,
  });
  const [isErrorModalVisible, setErrorModalVisible] = useState(false);

  useEffect(() => {
    dispatch(resetAssignmentTimer());

    if (assignmentResults?.answers === undefined || assignmentResults.answers.length === 0) {
      dispatch(setAnswer({ assignmentId: userAssignmentId, answer: new Array(questionsCount).fill("") }));
    }

    if (assignmentResults?.tips === undefined || assignmentResults.tips.length === 0) {
      dispatch(setTips({ userAssignmentId, tips: new Array(questionsCount).fill(false) }));
    }

    dispatch(startAssignmentTimer());

    return () => {
      dispatch(resetAssignmentTimer());
    };
  }, []);

  useEffect(() => {
    if (apiLazyError === undefined) return;

    processApiError(apiLazyError);
    setErrorModalVisible(true);
  }, [apiLazyError]);

  useEffect(() => {
    if (apiMutationError === undefined) return;

    processExerciseError(apiMutationError, {
      assignmentId: userAssignmentId,
      selfEvaluation: null,
      userAnswers: assignmentResults?.answers ?? [],
      timeToSolve: time,
    });
    setErrorModalVisible(true);
  }, [apiMutationError]);

  useEffect(() => {
    setRequestsLoading(isGettingAnswers && isSendingAnswers);
  }, [isGettingAnswers, isSendingAnswers]);

  useEffect(() => {
    const responseAnswers = answerData?.assignments.map(
      assignment =>
        assignment.assignment?.questions.map(question => {
          if (data.assignment?.assignment?.isAnswerCaseSensitive === true) return question.correctAnswers;
          return question.correctAnswers
            .filter((answer): answer is string => answer !== null)
            .map(answer => answer.toLocaleLowerCase());
        }),
    );

    if (
      responseAnswers === undefined ||
      responseAnswers[0] === undefined ||
      responseAnswers[0].length === 0 ||
      assignmentResults === undefined ||
      assignmentResults.answers === undefined
    ) {
      return;
    }

    dispatch(
      setAssignmentEvaluation({
        assignmentId: userAssignmentId,
        status: evaluateGenericType(
          assignmentResults.answers,
          responseAnswers[0],
          data.assignment?.assignment?.isAnswerCaseSensitive ?? false,
        ),
        answerVariants: evaluateAnswers(responseAnswers[0]),
        correctAnswers: responseAnswers[0],
      }),
    );
  }, [answerData]);

  useEffect(() => {
    if (!submit) return;

    if (typeof data.assignment?.id === "string") {
      trigger({ assignmentId: data.assignment.id }).catch(error => {
        console.error(error);
      });
    }

    if (assignmentResults === undefined || assignmentResults.answers === undefined || !evaluateAnswer) return;

    sendAnswers({
      assignmentId: userAssignmentId,
      selfEvaluation: null,
      userAnswers: assignmentResults.answers.map(answer => {
        return {
          answers: [answer ?? ""],
        };
      }),
      timeToSolve: time,
    })
      .then(response => {
        processAnswerResponse(response, data);

        if ("data" in response) {
          setAssignmentPoints({ points: response.data.answer.scoreReal, maxPoints: 1 });
          setErrorModalVisible(false);
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        dispatch(resetAssignmentTimer());
        dispatch(startAssignmentTimer());
        setSubmit(false);
      });
  }, [submit]);

  useEffect(() => {
    if (goNext) {
      dispatch(setTimeLearn({ assignmentId: userAssignmentId, timeLearn: time }));
      onNextClick();
      setGoNext(false);
    }
  }, [goNext]);

  const evaluateAnswers = (answers: Array<Array<string | null>>) => {
    if (
      data.assignment === undefined ||
      data.assignment === null ||
      data.assignment?.assignment === undefined ||
      data.assignment?.assignment === null
    )
      return [];
    const caseSensitive = data.assignment.assignment.isAnswerCaseSensitive;

    return data.assignment.assignment.questions.map((_question, qIndex) => {
      return getSolutionVariant(assignmentResults?.answers?.[qIndex], answers, qIndex, caseSensitive ?? false);
    });
  };

  const handleChange = (index: number, value: string) => {
    if (assignmentResults === undefined || assignmentResults.answers === undefined) return;

    const copy = [...assignmentResults.answers];
    copy[index] = value;
    dispatch(setAnswer({ assignmentId: userAssignmentId, answer: copy }));
  };

  const onSubmit = () => {
    setSubmit(true);
    dispatch(setIsTimerShown(false));
  };

  const handleNextClick = () => {
    dispatch(stopAssignmentTimer());
    setGoNext(true);
  };

  const areAnswersFilled = () => {
    try {
      const answers = assignmentResults?.answers;
      const areFilled = answers !== undefined && answers.length > 0 && answers.every(answer => answer.length > 0);
      return areFilled;
    } catch (error) {
      logGeneralExerciseError(error, data?.assignment?.id, assignmentResults?.answers);
      return false;
    }
  };

  const handleAbilityScoreSelect = (value: number, index: number) => {
    if (
      data.assignment === undefined ||
      data.assignment === null ||
      !isSelfEvaluationForEveryAssignment(data.assignment)
    )
      return;

    const newAbilityScore = changeDistinctAbilityScore(value, index, assignmentResults?.abilityScore, questionsCount);
    dispatch(setAbilityScore({ assignmentId: userAssignmentId, score: newAbilityScore }));
  };

  const getAbilityScoreOnIndex = (index: number) => {
    const abilityScore = assignmentResults?.abilityScore;

    if (abilityScore === undefined || typeof abilityScore === "number") return 0;

    return abilityScore[index];
  };

  if (isSelfEvaluation) {
    if (data.assignment === undefined || data.assignment === null) return null;

    return (
      <ExerciseSelfEvaluation
        userAssignment={data}
        userAssignmentId={userAssignmentId}
        results={assignmentResults}
        onNextClick={handleNextClick}
        saveAnswer={saveExerciseSelfEvaluation}
        evaluateAnswer={evaluateAnswer}
      />
    );
  }

  return (
    <Layout
      onSubmit={onSubmit}
      isAnalysis={assignmentResults?.correctAnswers !== undefined}
      goNext={handleNextClick}
      isSubmitDisabled={!areAnswersFilled() || isRequestsLoading}
      userAssignmentId={userAssignmentId}
      showAbilityScore={isAbilityScoreShown(data.assignment?.scoring?.selfEvaluation.selfEvaluation)}
      points={assignmentPoints.points ?? null}
      maxPoints={assignmentPoints.maxPoints}
    >
      <>
        <Latex>{data.assignment?.assignment?.assignmentText ?? ""}</Latex>
        {imageUrl !== null ? <img src={imageUrl} /> : null}

        <div className={"mt-20px"}>
          {data.assignment?.assignment?.questions.map((question, qIndex) => {
            const selfEvalOptions = getAbilityScoreOptions(
              isSelfEvaluation,
              assignmentResults?.answersVariants?.[qIndex],
            );

            return (
              <div key={qIndex} className="question">
                <Latex>{question.heading ?? ""}</Latex>

                {assignmentResults?.correctAnswers === undefined ? (
                  <ViceMoznostiDoleOption
                    index={qIndex}
                    placeholder={question.placeholder ?? ""}
                    options={question.options}
                    isLast={qIndex + 1 === data.assignment?.assignment?.questions.length}
                    onChange={handleChange}
                  />
                ) : (
                  <>
                    <AnswerExercise
                      subject={data.subject?.appName}
                      key={`answer-${qIndex}`}
                      index={qIndex}
                      assignment={data.assignment}
                      userAssignmentId={data.id}
                      type={assignmentResults?.answersVariants?.[qIndex] ?? "danger"}
                      timeToLearn={getTimeToLearn(data.timeToLearn)}
                      onNext={handleNextClick}
                    >
                      {assignmentResults?.answers?.[qIndex]}
                    </AnswerExercise>

                    {data.assignment !== undefined &&
                    data.assignment !== null &&
                    isSelfEvaluationForEveryAssignment(data.assignment) &&
                    question.showAbilityScore === true &&
                    selfEvalOptions !== null ? (
                      <>
                        <hr className={"my-25px"} />
                        <div className="mw-sm">
                          <AbilityScore
                            isSelfEvaluated={isSelfEvaluation}
                            options={selfEvalOptions}
                            selectedValue={getAbilityScoreOnIndex(qIndex)}
                            onValueSelect={(value: number) => {
                              handleAbilityScoreSelect(value, qIndex);
                            }}
                            index={qIndex}
                            key={qIndex}
                          />
                        </div>
                      </>
                    ) : null}
                  </>
                )}
              </div>
            );
          })}
        </div>
      </>
      <ErrorModal
        onCancel={() => {
          setEmptyExerciseAnswer(userAssignmentId, evaluateAnswers);
          setErrorModalVisible(false);
        }}
        onHide={() => {
          setErrorModalVisible(false);
        }}
        onRetry={() => {
          onSubmit();
        }}
        show={isErrorModalVisible}
      />
    </Layout>
  );
};

export default ViceMoznostiDole;
